import { request } from '../utils/request'

export function querySideHeadMenuList (type) {
  return request({
    url: 'fzdTextbookParentCats/queryByType',
    method: 'GET',
    params: {
      type
    }
  })
}

export function queryCascaderMenuList (sectionId, subjectId, versionId) {
  return request({
    url: 'fzdTextbook/queryByParentCatId',
    method: 'GET',
    params: {
      sectionId, subjectId, versionId
    }
  })
}

export function queryVideoList (textbookId, textbookSonCatId, page) {
  return request({
    url: 'fzdTextbookVideo/queryByTextbookId',
    method: 'post',
    data: {
      textbookId, textbookSonCatId, page,
      size: 16
    }
  })
}

export function queryCoursewareList (textbookId, textbookSonCatId, page) {
  return request({
    url: 'fzdTextbookPpt/queryByTextbookId',
    method: 'POST',
    data: {
      textbookId, textbookSonCatId, page,
      size: 16
    }
  })
}

export function queryQuestionsList (textbookId, textbookSonCatId, page, flag,level) {
  return request({
    url: 'fzdTextbookQuestions/queryByTextbookId',
    method: 'POST',
    data: {
      textbookId, textbookSonCatId, page,level,
      ignorePreFlag: flag,
      size: 10
    }
  })
}

export function getPptImgs (id) {
  return request({
    url: 'fzdPpt/findPptById',
    method: 'POST',
    data: {
      id
    }
  })
}

// 1ppt、2 视频、3 题库
export function joinTask (type, id, textbookId="") {
  return request({
    url: 'fzdPreTaskRes/add',
    method: 'POST',
    data: {
      type,
      resId: id,
      textbookId
    }
  })
}

export function queryTaskVideo (page) {
  return request({
    url: 'fzdPreTaskRes/queryPageVideo',
    method: 'POST',
    data: {
      page,
      size: 16
    }
  })
}

export function queryTaskCourseware (page) {
  return request({
    url: 'fzdPreTaskRes/queryPagePpt',
    method: 'POST',
    data: {
      page,
      size: 16
    }
  })
}

export function queryTaskQuestion (page) {
  return request({
    url: 'fzdPreTaskRes/queryPageQuestion',
    method: 'POST',
    data: {
      page,
      size: 10
    }
  })
}

export function deleteAllTask () {
  return request({
    url: 'fzdPreTaskRes/deleteAll',
    method: 'POST',
    data: {
      
    }
  })
}


export function clearTaskList () {
  return request({
    url: 'fzdPreTaskRes/deleteAll',
    method: 'POST',
    data: {
      
    }
  })
}

export function removeTaskItem (id) {
  return request({
    url: 'fzdPreTaskRes/delete',
    method: 'POST',
    data: {
      id
    }
  })
}

// type: 1: ppt 2: video 3: question
/* obj: {
  textbookid:''
  textbookSonCatId: '',
  source: 0： 系统 1： 我的
}
 */
export function allToTask (type, obj) {
  return request({
    url: 'fzdPreTaskRes/addForQueryAll',
    method: 'POST',
    data: {
      type,
      queryBO: obj,
    }
  })
}

export function getAllHeadMenu () {
  return request({
    url: 'fzdTextbookParentCatsRelation/querySSV',
    method: 'GET'
  })
}


export function downloadPPT (textbookId, pptId) {
  return request({
    url: 'fzdTeacher/downloadPPT',
    method: 'POST',
    data: {
      textbookId, pptId
    }
  })
}

// 获取布置资源总数
export function getAssignMsg() {
  return request({
    url: '/fzdPreTaskRes/taskCount',
    method: 'GET'
  })
}

// (新增)布置-课件-记录教师预览记录
export function recordPPTRead(data) {
  return request({
    url: '/fzdTeacherPreviewPptRecord/creat',
    method: 'POST',
    data
  })
}

// (新增)记录教师点击过下载课件按钮
export function recordPPTDownLoad(data) {
  return request({
    url: '/fzdTeacherUseLog/record/downPpt',
    method: 'GET',
    data
  })
}

