/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-28 10:13:58
 * @LastEditTime: 2022-03-15 13:02:48
 * @LastEditors: linkaiyan
 * @Reference: 
 */

import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { List, message, Avatar, Skeleton, Divider } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import MediaItem from './components/mediaItem';
import QuestionDetail from '../questionDetail';
import EmptyShow from '../EmptyShow';

import { TitleTabs, TitleItem, ContentList, ListContainer, QuestionContainer, HiddenContent, HideIcon } from './style';
import { queryTaskVideo, queryTaskCourseware, queryTaskQuestion } from '../../services/media';
import * as AssignAction from '../../pages/assign/store/actionCreator';
import {queryPageVideo,queryPagePpt,queryPageQuestion} from '@/services/class'
const TaskList = (props) => {
  const { getTaskCount, collapse=false,taskId,setTopicNum } = props;

  const [curType, setCurType] = useState(1);
  const [curPage, setCurPage] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([])
  const Tabs = [
    {name: '视频', value: 1},
    {name: '课件', value: 2, mid: 1},
    {name: '题目', value: 3}
  ]

  const changeType = (type) => {
    if(type === curType) {
      return;
    }
    setCurPage(0);
    setCount(0);
    setList([]);
    setCurType(type);
    setLoading(false)
    loadMoreData(type, 1);
  }

  const loadMoreData = useCallback(async (type, page) => {
    if(loading) {
      return;
    }
    setCurPage(page);
    setLoading(true);
    let res;
    /* 因为这里布置任务修改一进来要获取题目数量 */
    let topicData
    if(taskId) {
      topicData = await queryPageQuestion({page,size:10},taskId)
      setTopicNum(topicData.data.count)
    }
    if(type === 1) {
      res = taskId?await queryPageVideo({page,size:10},taskId):await queryTaskVideo(page);
    } else if(type === 2) {
      res = taskId?await queryPagePpt({page,size:10},taskId):await queryTaskCourseware(page);
    } else if(type === 3) {
      res = taskId?topicData:await queryTaskQuestion(page)
    }
    if(res.code === 0) {
      if(page === 1) {
        setList(res.data.rows);
      } else {
        setList(list.concat(res.data.rows));
      }
      setCount(res.data.count)
      setLoading(false)
    }
  }, [list, loading]) // eslint-disable-line react-hooks/exhaustive-deps

  const removeCallBack = (index) => {
    setList(() => {
      let newList = [...list];
      newList.splice(index, 1);
      return newList;
    });
    setCount(() => {
      return count - 1
    });
    getTaskCount();
  }

  useEffect(() => {
    loadMoreData(1, 1);
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <TitleTabs>
        {
          Tabs.map(item => {
            return (
              <TitleItem
                key={item.value}
                className={[curType === item.value ? 'active' : '', item.mid ? 'mid' : ''].join(' ')}
                onClick={() => changeType(item.value)}
              >
                {item.name}
              </TitleItem>
            )
          })
        }
      </TitleTabs>
      <ContentList id="scrollableDiv" className={collapse ? 'collapse' : ''}>
        {
          collapse ? 
            <HiddenContent>
              内容已收起
              <HideIcon />
            </HiddenContent> :
            (count > 0 ? 
            <InfiniteScroll
              dataLength={list.length}
              next={() => loadMoreData(curType, curPage+1)}
              hasMore={list.length < count}
              loader={<></>}
              endMessage={<div style={{padding: '40px 50px'}} ><Divider style={{color: '#999'}} plain>没有更多了</Divider></div>}
              scrollableTarget="scrollableDiv"
            >
              {
                curType === 1 || curType === 2 ? 
                <ListContainer>
                  {
                    list.map((item, index) => {
                      return(
                        <MediaItem
                          taskId={taskId}
                          key={item.id}
                          data={item}
                          state="1"
                          type={curType === 1 ? 'video' : 'courseware'}
                          index={index}
                          removeTaskCallBack={removeCallBack}
                        />
                      )
                    })
                  }
                </ListContainer> : 
                <QuestionContainer>
                  {
                    list.map((item, index) => {
                      return (
                        <div style={{marginBottom: '18px'}} key={item.id}>
                          <QuestionDetail
                            type="task"
                            taskId={taskId}
                            data={item}
                            index={index}
                            removeTaskCallBack={removeCallBack} />
                        </div>
                      )
                    })
                  }
                </QuestionContainer>
              }
            </InfiniteScroll> : <EmptyShow />)
        }
      </ContentList>
    </>
  )

}

const mapState = (state) => ({
  
})

const mapDispatch = (dispatch) => ({
  getTaskCount() {
    dispatch(AssignAction.getTaskCount())
  }
})

export default withRouter(connect(mapState, mapDispatch)(TaskList));