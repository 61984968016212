/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2022-03-16 15:07:53
 * @LastEditTime: 2022-03-24 14:54:01
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import * as constants from './constants';
import { fromJS, List } from 'immutable'
const defaultState = fromJS({
  textBookList: [],  // 测评教材列表
  curTextBook: '',   // 测评当前教材
  curTextBookTerm: '',  // 当前学期
  classList: [],     // 测评班级列表
  curClass: '',      // 测评当前班级
  startDate: '',     // 测评开始时间
  endDate: '',       // 测评结束时间
})


export default (state = defaultState, action) => {
  // 修改课本列表
  if(action.type === constants.CHANGE_TEXTBOOK_LIST) {
    return state.merge({
      textBookList: action.data
    })
  }

  // 修改当前课本
  if(action.type === constants.CHANGE_CURRENT_TEXTBOOK) {
    return state.set('curTextBook', action.data);
  }

  // 修改班级列表
  if(action.type === constants.CHANGE_CLASS_LIST) {
    return state.merge({
      classList: action.data
    })
  }

  // 修改当前班级
  if(action.type === constants.CHANGE_CURRENT_CLASS) {
    return state.set('curClass', action.data);
  }

  // 修改当前学期
  if(action.type === constants.CHANGE_CURRENT_TEXTBOOK_TERM) {
    return state.set('curTextBookTerm', action.data);
  }

  // 修改开始，结束时间
  if(action.type === constants.CHANG_TIMES) {
    return state.merge({
      startDate: action?.data?.startDate || '',
      endDate: action?.data?.endDate || ''
    })
  }
  
  return state;
}
