import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`

body {
  background-color: #FBFBFB;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.main-page {
  width: 1280px ;
  margin: 12px auto 0
}

.main-page .ant-btn-primary, .login-page .ant-btn-primary ,.ant-btn {
  border-radius: 4px;
}

.main-page .ant-btn-primary, .login-page .ant-btn-primary {
  text-shadow: none;
}
.main-page .ant-btn-primary[disabled], .login-page .ant-btn-primary[disabled] {
  border-color: #f5f5f5;
  background-color: #f5f5f5;
}

:root body {
  --swiper-theme-color: #0CCFC9
}

/* 设置滚动条的样式 */
*::-webkit-scrollbar {
  width: 3px;
  border-radius: 12px;
}

*::-webkit-scrollbar-track {
  background-color: #f8f8f8;
}

*::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: #ddd;
}

.video-modal .ant-modal-close-x, .ppt-modal .ant-modal-close-x {
  font-size: 28px;
}
.teacher-drawer {
  .ant-drawer-mask {
    background-color: transparent;
  }
  .ant-drawer-content-wrapper {
    box-shadow: none!important
  }
  .ant-drawer-content {
    background-color: transparent;
  }
  .ant-drawer-body {
   padding: 0;
  }
}
.ant-upload-list-item {
    display: none;
}
.ant-dropdown-menu-item:hover {
  color: #0CCFC9!important;
  background-color: #fff;
}
.ant-progress {
  display: flex;
}
.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.m-modal {
  .ant-modal-header {
    background: #F7F7F7!important;
    padding: 15px 18px;
  }
  .ant-modal-title {
    font-size: 14px;
    color: #3A3A3A;
    font-weight: bold;
  }
  .ant-modal-body {
    padding: 18px 18px 0;
  }
  .ant-modal-footer {
    padding: 0 18px 18px;
    border: none;
  }
  .ant-form-item {
    margin-bottom: 18px;
  }
  .ant-form-item-with-help .ant-form-item-explain {
    min-height: 0;
  }
}

.toast-message {
  .ant-message-notice-content {
    background: rgba(0, 0, 0, .6);
    border-radius: 6px;
    color: #fff;
    padding: 17px 20px;
    font-size: 16px;
  }
  .ant-message-custom-content {
    img {
      width: 26px;
      margin-right: 10px;
    }
  }
}

.ant-upload.ant-upload-select-picture-card {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
}

.custom-antd-button {
  /* 设置默认样式，例如背景色、字体大小等 */
  color: #0dcfc9;
  border-color: #0dcfc9; /* 边框颜色 */
}

`