/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-23 09:06:02
 * @LastEditTime: 2022-03-11 15:25:09
 * @LastEditors: linkaiyan
 * @Reference: 
 */

import { message } from 'antd';
import SuccessIcon from '../statics/successToast.png';
import ErrorIcon from '../statics/errorToast.png';

/**
 * @description: 
 * @param  {string} type  success, info, error
 * @return {*}
 */
export const showToast = ({ type='success', content, duration=2, icon }) => {
  message.destroy();
  message.success({
    content: content,
    className: 'toast-message',
    duration: duration,
    icon: icon || type === 'success' ? <img src={SuccessIcon} alt="" /> : type=== 'info' ? <></> : <img src={ErrorIcon} alt="" />
  });
}