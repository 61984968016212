/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2022-03-16 15:06:56
 * @LastEditTime: 2022-03-24 14:52:29
 * @LastEditors: linkaiyan
 * @Reference: 
 */
export const CHANGE_TABS_SHOW = 'evaluation/change_tabs_show';
export const CHANGE_TEXTBOOK_LIST = 'evaluation/change_textbook_list';
export const CHANGE_CURRENT_TEXTBOOK = 'evaluation/change_current_textbook';
export const CHANGE_CLASS_LIST = 'evaluation/change_class_list';
export const CHANGE_CURRENT_CLASS = 'evaluation/change_current_class';
export const CHANGE_CURRENT_TEXTBOOK_TERM = 'evaluation/change_current_textbook_term';
export const CHANG_TIMES = 'evaluation/chang_times';
