import { Provider} from 'react-redux'
import store from './store'
import Layout from './pages/layout'
import './App.less';
function App() {
  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
}

export default App;
