import { request, download } from '../utils/request'

export function queryClassList () {
  return request({
    url: 'fzdTeacherClass/queryAllByTeacherId',
    method: 'GET'
  })
}

export function createClass (name) {
  return request({
    url: 'fzdTeacherClass/createClass',
    method: 'POST',
    data: {
      name
    }
  })
}

export function queryClassGroup (classId) {
  return request({
    url: 'fzdTeacherClassGroup/queryAllByClassId',
    method: 'GET',
    params: {
      classId
    }
  })
}

export function createClassGroup (classId, name) {
  return request({
    url: 'fzdTeacherClassGroup/createGroup',
    method: 'POST',
    data: {
      classId,
      name
    }
  })
}

export function queryStudentList (data) {
  return request({
    url: 'fzdTeacherStudent/queryPage',
    method: 'POST',
    data
  })
}

export function createStudent (data) {
  return request({
    url: 'fzdTeacherStudent/createStudent',
    method: 'POST',
    data
  })
}

export function updateStudent (data) {
  return request({
    url: 'fzdTeacherStudent/updateStudent',
    method: 'POST',
    data
  })
}


export function queryAllGroupStudent (classId) {
  return request({
    url: 'fzdTeacherClassGroup/queryGroupAndStudentByClassId',
    method: 'get',
    params: {
      classId
    }
  })
}

export function createTaskRecord (data) {
  return request({
    url: 'fzdTeacherTaskRecord/createTask',
    method: 'POST',
    data
  })
}

export function deleteGroup (id) {
  return request({
    url: 'fzdTeacherClassGroup/deleteGroup',
    method: 'POST',
    data: {
      id
    }
  })
}

export function updateGroupName (id, name) {
  return request({
    url: 'fzdTeacherClassGroup/updateGroupName',
    method: 'POST',
    data: {
      id, name
    }
  })
}

export function updateStudentsToGroup (ids, groupId="") {
  return request({
    url: 'fzdTeacherStudent/batchUpdateGroupId',
    method: 'POST',
    data: {
      ids,
      groupId
    }
  })
}

export function updateStudentsToClass (ids, classId="") {
  return request({
    url: 'fzdTeacherStudent/batchUpdateClassId',
    method: 'POST',
    data: {
      ids,
      classId
    }
  })
}
export function deleteClass (id) {
  return request({
    url: 'fzdTeacherClass/deleteClass',
    method: 'POST',
    data: {
     id
    }
  })
}

export function changeClassName (name, id) {
  return request({
    url: 'fzdTeacherClass/updateClassName',
    method: 'POST',
    data: {
      name,
     id
    }
  })
}


export function downloadData (url,data) {
  return download({
    url,
    data
  })
}

export function getAllTeacher (name) {
  return request({
    url: 'fzdTeacher/findAllTeacher',
    method: 'get',
    params: {
      name
    }
  })
}

export function transferClass (classId,transferTeacherId) {
  return request({
    url: 'fzdTeacherClass/transferClass',
    method: 'post',
    data: {
      classId,transferTeacherId
    }
  })
}

export function getImportExcelEmplate () {
  return request({
    url: 'fzdTeacherStudent/getImportExcelEmplate',
    method: 'get',
    responseType: 'blob'
  })
}

export function exportStudents (data) {
  console.log("ids看看他的值是个啥>>>", data)
  return request({
    url: 'fzdTeacherStudent/exportExcel',
    method: 'POST',
    responseType: 'blob',
    data: {
      ids: data.ids,
      groupIds: data.groupIds,
      classIds: data.classIds,
      noGroupClassIds: data.noGroupClassIds
    }
  })
}

// (新增)班级-创建班级
export function createNewClass (data) {
  return request({
    url: '/fzdTeacherClass/createClass',
    method: 'POST',
    data
  })
}

// (新增)班级-创建班级-查询教师下所有教材(不分组)
export function getAllTextBook () {
  return request({
    url: '/fzdTeacherTextbookManage/queryListAll',
    method: 'GET',
  })
}

// (新增)班级-根据班级id获取班级学期教材信息
export function getClassMsg (id) {
  return request({
    url: `/fzdTeacherClass/${id}`,
    method: 'GET',
  })
}

// (新增)班级-根据班级id删除班级
export function deleteClassById (id) {
  return request({
    url: `/fzdTeacherClass/${id}`,
    method: 'DELETE',
  })
}

// (新增)班级-根据班级id修改班级信息
export function editClassById (data) {
  return request({
    url: `/fzdTeacherClass/update`,
    method: 'POST',
    data
  })
}

// (新增)查询省份
export function getAllProvince () {
  return request({
    url: `/sysArea/area`,
    method: 'GET',
  })
}

// (新增)查询省份下地市
export function getCityList (provinceId) {
  return request({
    url: `/sysArea/area/${provinceId}`,
    method: 'GET',
  })
}

// (新增)查询省份下地市下的区域
export function getAreaList (provinceId, cityId) {
  return request({
    url: `/sysArea/area/${provinceId}/${cityId}`,
    method: 'GET',
  })
}

// (新增)布置记录-布置信息
export function arrangeInfo (taskId) {
  return request({
    url: `/fzdTeacherTaskRecord/info/${taskId}`,
    method: 'GET',
  })
}


// (新增)布置记录-布置视频信息
export function queryPageVideo (params,taskId) {
  return request({
    url: `/fzdTeacherTaskRecord/queryPageVideo/${taskId}`,
    method: 'GET',
    params
  })
}

//(新增)布置记录-布置课件信息
export function queryPagePpt (params,taskId) {
  return request({
    url: `/fzdTeacherTaskRecord/queryPagePpt/${taskId}`,
    method: 'GET',
    params
  })
}

//(新增)布置记录-布置题目信息
export function queryPageQuestion (params,taskId) {
  return request({
    url: `/fzdTeacherTaskRecord/queryPageQuestion/${taskId}`,
    method: 'GET',
    params
  })
}


//(新增)布置记录-修改布置
export function TaskRecordEdit (data) {
  return request({
    url: `/fzdTeacherTaskRecord/update`,
    method: 'POST',
    data
  })
}
