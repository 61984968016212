/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-28 09:13:01
 * @LastEditTime: 2022-03-15 15:36:54
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import styled from 'styled-components';
import playPic from '../../../../statics/play-icon.png';
import addPic from '../../../../statics/add-icon.png';
import addActivePic from '../../../../statics/add-active-icon.png'
import playActivePic from '../../../../statics/play-active-icon.png'
import zw1Pic from '../../../../statics/zwt1.png'
import zw2Pic from '../../../../statics/zwt2.png'
export const MediaWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`

export const MediaItemWrap = styled.div`
  width: 214px;
  margin-left: 17px;
  margin-top: 18px;
`

export const MediaItemCon = styled.div`
  width: 100%;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  &.bg1{
    background-image: url(${zw1Pic});
    background-size: 100%;
  }
  &.bg2{
    background-image: url(${zw2Pic});
    background-size: 100%;

  }
`

export const MediaItemInner =  styled.div`
  color: #fff;
  padding: 0 20px;
`

export const MediaItemConTitle = styled.div`
  display: flex;
  align-items: center;
  width: 190px;
  color: #333;
  font-size: 14px;
  margin-top: 10px;
  &::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 18px;
    background: #0CCFC9;
    border-radius: 2px;
    margin-right: 8px;
    flex-shrink:0
  }
  .ellipsis {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }
`
export const MediaItemConSubTitle = styled.div`
  width:170px ;
  color: #C8D9D9;
  font-size: 12px;
  margin-top: 16px;
  margin-left: 9px;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
`

export const MediaItemConFooter = styled.div`
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background-color: #879C9C;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  padding: 0 12px;
`
export const MediaItemMask = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: space-around;
  padding: 33px 40px 70px;
  opacity: 0;
  transition: opacity .3s ;
  &:hover {
    opacity: 1;
  }
`

export const PlayBtn = styled.div`
  width: 30px;
  height: 30px;
  background-image: url(${playPic});
  background-size: cover;
  background-repeat:no-repeat;
  &:hover, &:active {
    background-image: url(${playActivePic});
  }
`

export const AddBtn = styled.div`
  width: 30px;
  height: 30px;
  background-image: url(${addPic});
  background-size: cover;
  background-repeat:no-repeat;
  &:hover, &:active {
    background-image: url(${addActivePic});
  }
`
export const BtnWrap =  styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
`
export const RemoveBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-weight: 700;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .5);
  span {
    color: #fff;
    font-size: 20px;
  }
  &:active {
    background-color: var(--swiper-theme-color);
  }
  &:hover {
    background-color: var(--swiper-theme-color);
  } 
`
export const DownBtn = RemoveBtn