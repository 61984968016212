import styled from 'styled-components';
import logoPic from '../../statics/logo.png';

export const HeaderWrapper = styled.div`
	z-index: 1;
	position: relative;
  width: 100%;
	height: 60px;
	background-color: #fff;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);
`;

export const HanderContainer = styled.div`
	width: 1280px;
	margin: 0 auto;
	position: relative;
	height: 100%;
	.active .navItem {
		color: var(--swiper-theme-color);
	}
`
export const Logo = styled.div`
	position: absolute;
	left: 0;
	top: 10px;
	display: block;
	width: 248px;
	height: 43px;
	background: url(${logoPic});
	background-size: contain;
	background-repeat: no-repeat;
`;

export const Nav = styled.div`
	position: absolute;
	left: 300px;
	right: 0;
	width: 600px;
	height: 100%;
	
`
export const NavItem = styled.div`
	display: inline-block;
	line-height: 60px;
	padding: 0 10px;
	font-size: 16px;
	color: #333;
	cursor: pointer;
	margin-right: 20px;
	&:hover {
		color: var(--swiper-theme-color);
	}
	&.active {
		color: var(--swiper-theme-color);
	}
`

export const AvatarWrap = styled.div`
	position: absolute;
	top: 0px;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	/* width: 80px; */
	height: 100%;
`

export const LoginBtnStyle = {
	background: '#0CCFC9',
	borderColor: '#0CCFC9' 
}

export const UserInfoWrap  = styled.div` 
	width: 214px;
	height: 138px;
	background: #FFFFFF;
	border: 1px solid #EAEAEA;
	border-radius: 0px 0px 6px 6px;
`
export const UserInfoCon  = styled.div`
	padding: 20px;
 	box-sizing: border-box;
	display: flex;
	border-bottom: 1px solid #EAEAEA;
`

export const UserInfoConInner  = styled.div`
	margin-left: 12px;
`

export const UserInfoName  = styled.div`
	font-size: 16px;
	color: #333;
	line-height: 1.4;
`
export const UserInfoAccount  = styled.div`
	font-size: 12px;
	color: #999;
`

export const LoginOutBtn  = styled.div`
	width: 100%;
	height: 51px;
	line-height: 50px;
	text-align: center;
	color: #666;
	font-size: 14px;
	cursor: pointer;
	&:hover {
		background: rgb(248,248,248);
	}
`
export const TagWrap = styled.div`
	padding:10px;
	background:#48D5D0 ;
	border-radius:10px ;
	color:#fff ;
	height:20px ;
	display:flex ;
	align-items: center;
	margin-left:5px;
`
