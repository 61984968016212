/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2022-01-19 14:53:29
 * @LastEditTime: 2022-01-21 13:17:11
 * @LastEditors: linkaiyan
 * @Reference: 
 */

export const CHANGE_CUR_BOOK_TASK_LIST = 'learntrack/change_cur_book_task_list'

export const CHANGE_CUR_BOOK_TASK_ID = 'learntrack/change_cur_book_task_id'

export const CHANGE_LEARN_BOOK_DATA = 'learntrack/change_learn_book_data'

export const CHANGE_LEARN_BOOK_ID = 'learntrack/change_learn_book_id'