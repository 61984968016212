import * as constants from './constants';
import { fromJS } from 'immutable'
const defaultState = fromJS({
  list: [],
  groupData: {},
  currClassId: '',
  currClassGroupId: '',
  studentList: [],
  allStudent:[],
  selectedRowKeys: [],
  choseRowsInCurGroup: [], // 记录在当前分组页所选中的学生数量
  studentCount: 0,
  teacherList:[],
  analysisExcelData: [],
  importResult: [],
  searchData: {
    page: 1,
    size: 10,
    classId: '',
    groupId: '',
    phone: '',
    account: ''
  },
  isNoGroup: false,
  isExport: false, // 是否展示出导出按钮
  allChecked: false, // 导出的全选按钮
})

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  if (action.type === constants.CHANGE_CLASSLIST) {
   return state.set('list', action.data)
  }
  if (action.type === constants.CHANGE_CURRCLASSID) {
    return state.set('currClassId', action.value)
  }
  if (action.type === constants.CHANGE_CLASSGROUP) {
    return state.set('groupData', action.data)
  }
  if (action.type === constants.CHANGE_CURRCLASSGROUPID) {
    return state.set('currClassGroupId', action.value)
  }
  if (action.type === constants.CHANGE_STUDENTDATA) {
    return state.merge({
      studentList: action.data.rows,
      studentCount:  action.data.count
    })
  }
  if (action.type === constants.CHANGE_SEARCHDATA) {
    return state.set('searchData', action.data)
  }
  if (action.type === constants.CHANGE_AllSTUDENTDATA) {
    return state.set('allStudent', action.data)
  }
  if (action.type === constants.CHANGE_SELECTEDROWKEYS) {
    let temp = Object.assign([], action.data)
    return state.set('selectedRowKeys', temp)
  }
  if (action.type === constants.CHANGE_TEACHERLIST) {
    return state.set('teacherList', action.data)
  }
  if (action.type === constants.CHANGE_ANEXCELDATA) {
    return state.set('analysisExcelData', action.data)
  }
  if(action.type === constants.CHANGE_ISNOGROUP) {
    return state.set('isNoGroup', action.data)
  }
  if(action.type === constants.CHANGE_ISEXPORT) {
    return state.set('isExport', action.data)
  }
  if(action.type === constants.CHANGE_ALLCHECKED) {
    return state.set('allChecked', action.data)
  }
  if(action.type === constants.CHANGE_CHOSEROWSINCURGROUP) {
    return state.set('choseRowsInCurGroup', action.data)
  }
  return state
}