/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-28 09:12:52
 * @LastEditTime: 2021-12-29 11:36:34
 * @LastEditors: linkaiyan
 * @Reference: 
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Tooltip } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { MediaItemWrap, MediaItemCon, MediaItemConFooter, MediaItemMask, PlayBtn, AddBtn, BtnWrap, MediaWrap, RemoveBtn, MediaItemInner, MediaItemConTitle, DownBtn } from './style'
import { removeTaskItem } from '../../../../services/media';
const MediaItem = (props) => {
  const { data, type, state, index, removeTaskCallBack,taskId} = props;
  
  const handleRemoveTaskItem = async () => {
    const res = await removeTaskItem(data.preId);
    if(res.code === 0) {
      if(removeTaskCallBack) {
        removeTaskCallBack(index);
      }
    }
  }
  
  const handleJoinTaskItem = () => {
    
  }

  return (
    <>
      {
        data && 
        <MediaItemWrap key={data.id}>
          <MediaItemCon style={{backgroundImage: data.image? `url(${data.image})` : ''}} className={type==='video'?'bg1': 'bg2'}>
            <MediaItemMask>
              {
                state === '1' && !taskId ?
                <BtnWrap><RemoveBtn onClick={()=>{handleRemoveTaskItem(type, data.preId)}} ><MinusOutlined /></RemoveBtn>移除</BtnWrap> : 
                state !== '1' && !taskId ?
                <BtnWrap><AddBtn onClick={()=>{handleJoinTaskItem(type, data.id)}} />加入</BtnWrap>:<></>
              }
            </MediaItemMask>
            <MediaItemConFooter>
              <Tooltip title={data.title}>
                {data.title}
              </Tooltip>
              </MediaItemConFooter>
          </MediaItemCon>
        </MediaItemWrap>
      }
    </>
  )
}

const mapState = (state) => ({
  
})

const mapDispatch = (dispatch) => ({
  
})

export default withRouter(connect(mapState, mapDispatch)(MediaItem));
