/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2022-01-06 10:39:47
 * @LastEditTime: 2022-01-10 18:35:51
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(0, 0, 0, .05); */
`;