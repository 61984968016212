
import * as constants from './constants';
import * as services from '../../../services/media'
import { message } from 'antd'

const changeVideoData = (list, count) => ({
  type: constants.CHANGE_VIDEODATA,
  list, count
})

const changeCoursewareData = (list, count) => ({
  type: constants.CHANGE_COURSEWAREDATA,
  list, count
})
const changeQuestionData = (list, count) => ({
  type: constants.CHANGE_QUESTIONDATA,
  list, count
})

export const joinTask = (type, id, bookid) => {
  return async dispatch => {
    let _type = ''
    ;(type === 'video') && ( _type = 2)
    ;(type === 'courseware') &&(_type = 1)
    ;(type === 'question') && (_type = 3)
    const res = await services.joinTask(_type, id, bookid)
    message[res.code===0?'success': 'error'](res.msg, 1)
    if(_type === 2) {
      const res = await services.queryTaskVideo(1)
      dispatch(changeVideoData(res?.data?.rows, res?.data?.count))
      return
    }
    if(_type === 1) {
      const res = await services.queryTaskCourseware(1)
      dispatch(changeCoursewareData(res?.data?.rows, res?.data?.count))
    }
    if(_type === 3) {
      const res = await services.queryTaskQuestion(1)
      dispatch(changeQuestionData(res?.data?.rows, res?.data?.count))
    }
  }
}

export const getTaskVideoList = (page) => {
  return async dispatch => {
    const res = await services.queryTaskVideo(page)
    dispatch(changeVideoData(res?.data?.rows, res?.data?.count))
  }
}

export const getTaskCoursewareList = (page) => {
  return async dispatch => {
    const res = await services.queryTaskCourseware(page)
    dispatch(changeCoursewareData(res?.data?.rows, res?.data?.count))
  }
}

export const getTaskQuestionList = (page) => {
  return async dispatch => {
    const res = await services.queryTaskQuestion(page)
    dispatch(changeQuestionData(res?.data?.rows, res?.data?.count))
  }
}

export const clearTaskList = () => {
  return async dispatch => {
    const res = await services.clearTaskList()
    message[res.code === 0?'success': 'error'](res.msg)
    if(res.code === 0) {
      const res1 = await services.queryTaskVideo(1)
      const res2 = await services.queryTaskCourseware(1)
      const res3 = await services.queryTaskQuestion(1)
      dispatch(changeVideoData(res1?.data?.rows, res1?.data?.count ?? 0))
      dispatch(changeCoursewareData(res2?.data?.rows, res2?.data?.count ?? 0))
      dispatch(changeQuestionData(res3?.data?.rows, res3?.data?.count ?? 0))
    }
  }
}

export const removeTaskItem = (type, id) => {
  return async dispatch => {
    const res = await services.removeTaskItem(id)
    message[res.code === 0?'success': 'error'](res.msg, 1)
    if(type === 'question') {
      const res = await services.queryTaskQuestion(1)
      dispatch(changeQuestionData(res?.data?.rows, res?.data?.count ?? 0))
      return
    }
    if(type === 'video') {
      const res = await services.queryTaskVideo(1)
      dispatch(changeVideoData(res?.data?.rows, res?.data?.count ?? 0))
      return
    }
    if(type === 'courseware') {
      const res = await services.queryTaskCourseware(1)
      dispatch(changeCoursewareData(res?.data?.rows, res?.data?.count ?? 0))
    }
  }
}

export const addAllToTask = (type, ids, source) => {
  return async dispatch => {
    let _type = ''
    let _source;
    if(type==='question') {
      _type = 3;
      _source = source;
    }
    const obj = {
      textbookId: ids[1],
      textbookSonCatId: ids[0]
    }
    if(_type === 3) {
      obj.source = source
    }
    const res = await services.allToTask(_type, obj)
    message[res.code === 0?'success': 'error'](res.msg, 1)
    if(res.code === 0 ) {
      const res = await services.queryTaskQuestion(1)
      dispatch(changeQuestionData(res?.data?.rows, res?.data?.count ?? 0))
    }
  }
}

export const downLoadPPT = (bookId, pptId, title) => {
  return async dispatch => {
    const {code, data, msg} = await services.downloadPPT(bookId, pptId)
    
    if(code ===0) {
      // var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
      // var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
      
      var x = new XMLHttpRequest();
        x.open("GET", data, true);
        x.responseType = 'blob';
        x.onload=function(e) {
          var url = window.URL.createObjectURL(x.response)
          var a = document.createElement('a');
          a.download = `${title}.pptx`
          a.href = url;
          a.click()
        }
        x.send();
      // if(isIE){
      // 如果为IE 构造一个虚拟的a标签 以便于跳转到对应的URL
        // var gotolink = document.createElement('a');
        // gotolink.style.display = 'none';
        // gotolink.href = data;
        // gotolink.setAttribute("target", "_blank");
        // document.body.appendChild(gotolink);
        // gotolink.click();
        // return
      // } else {
      //   window.open(data)
      // }
    } else {
      message.error(msg)
    }
  }
}