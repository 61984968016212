/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-28 10:14:04
 * @LastEditTime: 2021-12-29 14:56:07
 * @LastEditors: linkaiyan
 * @Reference: 
 */

import styled from 'styled-components';
import HidePic from '../../statics/hide.png';

export const TitleTabs = styled.div`
  display: flex;
  padding: 10px 0;
  background: #F7F7F7;
`;

export const TitleItem = styled.div`
  flex: 1;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  &.mid {
    border-left: 2px solid #EDEDED;
    border-right: 2px solid #EDEDED;
  }
  &.active {
    font-size: 16px;
    color: #0CCFC9;
  }
  &:hover {
    color: #0CCFC9;
  }
`;

export const ContentList = styled.div`
  height: 560px;
  overflow: auto;
  transition: height .3s linear;
  &.collapse {
    height: 69px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const QuestionContainer = styled.div`
  padding: 18px;
`;

export const HiddenContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #999999;
  font-weight: 400;
`;

export const HideIcon = styled.img.attrs({
  src: HidePic
})`
  width: 16px;
  margin-left: 6px;
`;