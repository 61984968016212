import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Popconfirm } from 'antd'
import { connect } from 'react-redux'
// import { actionCreators } from '../../pages/arrangement/store'
import { actionCreators as classAction } from '../../pages/class/store'
import {SideBarWrap, SideBarItem,SideBarBtn} from './style'
import TaskList from '../TaskList';
import * as AssignAction from '../../pages/assign/store/actionCreator';
class SideBar extends Component{
  handleArrangement() {
    this.props.getClassList()
    this.props.history.push('/issue')
  }
  handleClear () {
    this.props.clearTaskList()
  }
  showTaskList() {
    this.setState({
      visible: true
    })
  }
  hideTaskList() {
    this.setState({
      visible: false
    })
  }
  toAssign() {
    this.props.history.push('/issue');
    this.hideTaskList();
  }
  state= {
    visible: false
  }
  render() {
    const { videoNum = 0, coursewareNum = 0, questionNum=0, videoTaskNum=0, pptTaskNum=0, questionTaskNum=0 } = this.props
    return (
      <SideBarWrap>
        <SideBarItem>
          <div className="num">{videoTaskNum}</div>
          <div className="title">视<br />频</div>
        </SideBarItem>
        <SideBarItem>
          <div className="num">{pptTaskNum}</div>
          <div className="title">课<br />件</div>
        </SideBarItem>
        <SideBarItem className="last">
          <div className="num">{questionTaskNum}</div>
          <div className="title">题<br />目</div>
        </SideBarItem>
        <SideBarBtn className='check' onClick={this.showTaskList.bind(this)}>
          查看内容
        </SideBarBtn>
          <SideBarBtn className="primary" onClick={this.handleArrangement.bind(this)}>
            布置
          </SideBarBtn>
        <SideBarBtn>
          
          <Popconfirm
            title="是否清空所有数据？"
            okText="是"
            cancelText="否"
            placement="left"
            onConfirm={this.handleClear.bind(this)}
          >
            清空
          </Popconfirm>
        </SideBarBtn>

        <Modal
          bodyStyle={{padding: '0'}} 
          centered
          visible={this.state.visible}
          destroyOnClose={true}
          closable={false}
          okText="去布置"
          onOk={() => this.toAssign()}
          onCancel={() => this.hideTaskList()}
          width={946}
        >
          <TaskList />
        </Modal>
      </SideBarWrap>
    )
  }
  componentDidMount() {
    // this.props.getTaskVideoList()
    // this.props.getTaskCoursewareList()
    // this.props.getTaskQuestionList()
    this.props.getTaskCount();
  }
}

const mapState = (state) => ({
  // videoNum: state.getIn(['arrangement', 'videoNum']),
  // coursewareNum: state.getIn(['arrangement', 'coursewareNum']),
  // questionNum: state.getIn(['arrangement', 'questionNum']),
  pptTaskNum: state.getIn(['assign', 'pptTaskNum']),
  videoTaskNum: state.getIn(['assign', 'videoTaskNum']),
  questionTaskNum: state.getIn(['assign', 'questionTaskNum']),
})

const mapDispatch = (dispatch) => ({
	// getTaskVideoList() {
  //   dispatch(actionCreators.getTaskVideoList(1))
  // },
  // getTaskCoursewareList() {
  //   dispatch(actionCreators.getTaskCoursewareList(1))
  // },
  // getTaskQuestionList() {
  //   dispatch(actionCreators.getTaskQuestionList(1))
  // },
  clearTaskList() {
    dispatch(AssignAction.clearTaskList())
  },
  getClassList () {
    dispatch(classAction.getClassList())
  },
  getTaskCount () {
    dispatch(AssignAction.getTaskCount())
  },
})

export default withRouter(connect(mapState, mapDispatch)(SideBar))