
import * as constants from './constants';

export const changeCurrPage = (value) => ({
  type: constants.CHANGE_CURRPAGE,
  value
})

export const changisLoading = (data) => ({
  type: constants.CHANG_LOADING,
  data
})

// 获取布置资源总数
export const setLoading = (data) => {
  return dispatch => {
    dispatch(changisLoading(data));
  }
}