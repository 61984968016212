
import * as constants from './constants';
import { fromJS } from 'immutable'
const defaultState = fromJS({
  list: {},
  showLoading: false
})


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  if (action.type === constants.CHANGE_VIDEOLIST) {
   return state.set('list', action.data)
  }
  if (action.type === constants.CHANGE_SHOWLOADING) {
    return state.set('showLoading', action.value)
   }
  return state
}