import axios from 'axios'
import { message } from 'antd';
import { loginOutFn } from '../common/header'
import { setLoading } from '../common/header/store/actionCreators';

import store from '../store/index';

const baseURL = process.env.REACT_APP_BASE_URL;
const instance = axios.create({
  // baseURL: window.global_url.base_api_url, // 182.254.188.186  192.168.1.101  49.233.4.253
  // baseURL: 'http://192.168.0.225:30021/teacher/',
  // baseURL: 'http://app10.fenzhidao.com/teacher/',
  baseURL,
  timeout: 60000
})

let requestNum = 0;

instance.interceptors.request.use(config => {
  if(!config.noloading) {
    requestNum++;
  }
  if(requestNum > 0) {
    store.dispatch(setLoading(true));
  }
  config.headers.token = localStorage.getItem('token') || ''
  // config.headers.token = '5a47fd656a2a422d815f194c2fce0528-91f7a228-cbd0-4822-b664-99d27a352967'
  return config
})

instance.interceptors.response.use(res => {
  requestNum--;
  if(requestNum === 0) {
    store.dispatch(setLoading(false))
  }
  return res;
}, err => {
  requestNum--;
  if(requestNum === 0) {
    store.dispatch(setLoading(false));
  }
})

export const request = ({ url, params = {}, data = {},  method="GET", responseType='', noloading=false}) => {
  return new Promise((resolve, reject) => {
    instance.request({
      url: `${url}?v=${Math.random(10)}`,
      data,
      params,
      method,
      responseType,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      noloading
    }).then(res => {
      // if(res && res.data.code === 1000) { //登录过期
      //   loginOutFn&&loginOutFn()
      //   return
      // }
      // resolve(res.data)

      if(res && res.data.code !== 1000) {
        resolve(res.data)
      } else if(res && res.data.code === 1000) { // 登录过期
        loginOutFn&&loginOutFn();
        reject(res.data.code);
      }
    }, err => {
      message.error('请求超时')
      reject(err)
    })
  })
}


export const download = ({url, data = {}}) => {
  return new Promise((resolve, reject) => {
    instance.request({
      url,
      data,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      if(res.data.code === 1000) { //登录过期
        loginOutFn()
        return
      }
      resolve(res.data)
    }, err => {
      message.error('请求超时')
      reject(err)
    })
  })
}
