/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-20 10:56:05
 * @LastEditTime: 2022-01-20 16:16:46
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import * as constants from './constants';
import * as services from '../../../services/assign'
import {queryVideoList,queryCoursewareList} from '../../../services/media'
import * as services2 from '../../../services/media';
import { showToast } from '../../../utils/toast';


// 修改当前教材信息
export const changeCurTextBookData = (data) => ({
  type: constants.CHANGE_CURRENT_TEXTBOOK_DATA,
  data
})

// 修改当前选中id
const changeSelectId = (data) => ({
  type: constants.CHANGE_SELECT_ID,
  data
})

// 修改问题列表数据
const changeQuestionListData = (data) => ({
  type: constants.CHANGE_QUESTION_LIST_DATA,
  data
})

// 修改布置资源总数
const changeTaskCount = (data) => ({
  type: constants.CHANGE_TASK_COUNT,
  data
})

// 修改清空次数
const changeClearCount = (data) => ({
  type: constants.CHANGE_CLEAR_COUNT,
  data
})

//修改视频数据
const changeVideoList = (data) => ({
  type: constants.CHANGE_VIDEOLIST,
  data
})

const changeShowLoading = value => ({
  type: constants.CHANGE_SHOWLOADING,
  value
})

//课件
const changeCoursewareList = (data) => ({
  type: constants.CHANGE_COURSEWARELIST,
  data
})

//教师可选年级
const getGradeData = (data)=>({
  type: constants.GET_GRADE_SELECT,
  data
})

const changeCurSelectedAssignId = data => ({
  type: constants.CHANGE_SELECT_ASSIGN_ID,
  data
})

export const setCurSelectedAssignId = (selectAssignId) => {
  return async dispatch => {
    dispatch(changeCurSelectedAssignId(selectAssignId));
  }
}
/* 获取布置任务id */
const getTaskId = (data)=>({
  type: constants.GET_TASKID,
  data
})


// 获取当前教材信息
export const getCurTextBookData = (fn) => {
  return async dispatch => {
    const res = await services.getCurTextBook();
    // 在这里如果有章节id的话传章节id
    dispatch(changeCurTextBookData(res.data))
    if(!res?.data?.id) { // 当前没有教材
      dispatch(clearTaskList(false));
      if(fn) {
        fn();
      }
    }
  }
}

// 修改当前选中id
export const changeCurIdSelectedId = (selectedId) => {
  return async dispatch => {
    dispatch(changeSelectId(selectedId))
  }
}

// 获取题目信息
export const getQuestionList = (data) => {
  return async dispatch => {
    const res = await services.getQuestionList(data);
    dispatch(changeQuestionListData(res.data))
  }
}


// 系统题目加入我的题库
export const AddToMyQuestion = (data, data2) => {
  return async dispatch => {
    const res = await services.AddToMyQuestion(data);
    if(res.code == 0) {
      showToast({
        type: 'success',
        content: '添加题库成功'
      })
      // 重新获取系统题目列表
      const res2 = await services.getQuestionList(data2);
      dispatch(changeQuestionListData(res2.data))
    } else {
      showToast({
        type: 'error',
        content: res.msg
      })
    }
  }
}

// 获取布置资源总数
export const getTaskCount = () => {
  return async dispatch => {
    const res = await services2.getAssignMsg();
    dispatch(changeTaskCount(res.data));
  }
}

/**
 * @description: 清空任务列表
 * @param  {boolean} showTips  是否展示提示
 */
export const clearTaskList = (showTips=true) => {
  return async dispatch => {
    const res = await services2.clearTaskList();
    if(showTips) {
      showToast({
        type: res.code === 0?'success': 'error',
        content: res.msg
      })
    }
    if(res.code === 0) {
      let data = {
        1: {count: 0},
        2: {count: 0},
        3: {count: 0}
      }
      dispatch(changeTaskCount(data))
      dispatch(changeClearCount()) // 用于列表的刷新
    }
  }
}


//获取视频数据
export const getDataList = (textbookId, textbookSonCatId, page) => {
  return async dispatch => {
    dispatch(changeShowLoading(true))
    try {
      const res = await queryVideoList(textbookId, textbookSonCatId, page)
      dispatch(changeVideoList(res.data))
    } catch (error) {
      
    } finally {
      dispatch(changeShowLoading(false))
    }
  }
}


//获取课件数据
export const getCoursewareList = (textbookId, textbookSonCatId,page) => {
  return async dispatch => {
    // dispatch(changeShowLoading(true))
    try {
      const res = await queryCoursewareList(textbookId, textbookSonCatId, page)
      if( res.code === 0 ){
        dispatch(changeCoursewareList(res.data))
      } else {
        dispatch(changeCoursewareList([]))
      }
    } catch (error) {
      
    } finally {
      // dispatch(changeShowLoading(false))
    }
  }
}

//获取添加教材教师可选年级
export const getGradeSelection=()=>{
  return async dispatch => {
    const res = await services.GradeSelection();
    dispatch(getGradeData(res.data))
  }
}

export const setTaskId = (id) => {
  return async dispatch => {
    dispatch(getTaskId(id))
  }
}
