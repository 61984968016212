/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2022-01-19 14:53:18
 * @LastEditTime: 2022-01-21 13:16:40
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import * as constants from './constants';

import { getAllTask } from '../../../services/assign';

// 修改当前教材任务列表
export const changeCurBookTaskList = (data) => ({
  type: constants.CHANGE_CUR_BOOK_TASK_LIST,
  data
})

// 修改当前任务
export const changeCurBookTaskId = (data) => ({
  type: constants.CHANGE_CUR_BOOK_TASK_ID,
  data
})

// 修改学情管理当前教材信息
export const changeLearnBookData = (data) => ({
  type: constants.CHANGE_LEARN_BOOK_DATA,
  data
})
// 修改学情管理当前教材id
export const changeLearnBookId = (data) => ({
  type: constants.CHANGE_LEARN_BOOK_ID,
  data
})


// 更新当前教材任务列表及当前选中任务
export const setCurBookTaskList = (curBookId, updateCurTask) => {
  return async dispatch => {
    const res = await getAllTask(curBookId);
    dispatch(changeCurBookTaskList(res.data));
    if(updateCurTask) {
      if(res.data.length > 0) {
        dispatch(changeCurBookTaskId(res.data[0].taskId));
      } else {
        dispatch(changeCurBookTaskId(''));
      }
    }
  }
}

// 获取当前教材选中任务
export const setCurBookdTaskId = (id) => {
  return async dispatch => {
    dispatch(changeCurBookTaskId(id))
  }
}
