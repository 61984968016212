import { useCallback, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch}  from 'react-router-dom'
import MModalTips  from '@/common/MModalTips';
import { connect } from 'react-redux';
import Header from '../../common/header';
import SideBar from '../../common/sideBar'
import MLoading from '../../common/MLoading';
import {actionCreators} from '../login/store'
import moment from 'moment';
import { recordTime, recordTime2,closeModal } from '@/services/login';
import { recordTeacherUse } from '@/utils/commonFun';
import Loadable from './loadable';
const Login = Loadable(() => import('../login'));
const ClassPage = Loadable(() => import('../class'));
const AssignPage = Loadable(() => import('../assign'));
const TrackPage = Loadable(() => import('../learntrack'));


// const EvaluationPage = Loadable(() => import('../evaluation'));
const CheckPage = Loadable(() => import('../check'));
const Issue = Loadable(() => import('../issue'));
const UpdatePassword = Loadable(() => import('../login/components/UpdatePassword'));
const DataOverview = Loadable(() => import('../dataOverview'));
const Layout = (props) => {
  const { isLogin,userInfo,isShowExpired,frozenMsg,setExpired,taskId} = props;
  // type: 1 正常调用， 0：beacon
  const recordLoginTime = useCallback((type = 1) => {
    // 是否有上次登录的记录
    let lastTime = localStorage.getItem('l_o_g_t');
    let userInfo = localStorage.getItem('userInfo');
    if(lastTime) {
      // 登录时间是否今天
      if(moment(lastTime).isSame(moment().format('YYYY-MM-DD'))) {
        // 今天记录过了
      } else {
        // 今天还未记录
        if(userInfo) {
          const teacherId = JSON.parse(userInfo).id;
          type === 0 ? recordTime2(teacherId) : recordTime(teacherId);
          localStorage.setItem('l_o_g_t', moment().format('YYYY-MM-DD'));
        }
      }
    } else {
      if(userInfo) {
        const teacherId = JSON.parse(userInfo).id;
        type === 0 ? recordTime2(teacherId) : recordTime(teacherId);
        localStorage.setItem('l_o_g_t', moment().format('YYYY-MM-DD'));
      }
    }
  }, []);
  /*不再提醒关闭弹窗 */
  const hideModal =(event)=>{
    const targetElement = event.target; 
    if(targetElement.tagName === 'SPAN') {
      closeModal().then(res=>{
        if(res.code === 0){
          setExpired(false);
        }
      })
    }else{
      setExpired(false);
    }
  }

  const browserClose = useCallback((e) => {
    if((e.type == 'visibilitychange' && document.visibilityState === 'hidden') || e.type !== 'visibilitychange') {
      return recordLoginTime(0)
    }
  }, [recordLoginTime])
  useEffect(() => {
    // const {aplus_queue} = window;
    
    // aplus_queue.push({
    //   action: 'aplus.sendPV',
    //   arguments: [{
    //     is_auto: false
    //     }, {
    //       path: window.location.pathname
    //     }]
    //   });
    window._hmt && window._hmt.push(['_trackPageview', window.location.pathname]);
  });
  useEffect(() => {
    recordLoginTime(1);
    recordTeacherUse();
    // 事件监听
    window.addEventListener('focus', recordLoginTime)
    
    // 不监听beforeunload，unload和pagehide是因为不同浏览器有不同的实现，有些浏览器不会触发这些事件,不支持visibilitychange的话用pagehide代替
    if('visibilityState' in document) { // 判断浏览器是否支持visibilityState事件
      window.addEventListener('visibilitychange', browserClose)
    } else { // 避免有些浏览器不支持visibilitychange
      window.addEventListener('pagehide', browserClose)
    }
    
    return () => {
      // 取消事件监听
      window.removeEventListener('focus', recordLoginTime)
      window.removeEventListener('visibilitychange', browserClose)
      window.removeEventListener('pagehide', browserClose)
    }
  }, [recordLoginTime, browserClose])
  return (
    <>
      <BrowserRouter>
        <>
          {isLogin ? <Header /> : <Redirect to='/login'/>}
          {
            isLogin && 
            <div className="main-page">
              {isLogin && !taskId? <SideBar />:<></>}
              {/* {isLogin && <SideBar />} */}
              <Switch>
                {/* <Route path='/video' exact component={VideoPage}></Route>
                <Route path='/courseware' exact component={CoursewarePage}></Route>
                <Route path='/question' component={QuestionPage}></Route> */}
                <Route path='/class' exact component={ClassPage}></Route>
                <Route path='/assign' component={AssignPage}></Route>
                <Route path='/dataOverview' component={DataOverview}></Route>
                <Route path='/learntrack' component={TrackPage}></Route>
                {/* <Route path='/evaluation' component={EvaluationPage}></Route> */}
                {/* <Route path='/arrangement' exact component={ArrangementPage}></Route> */}
                <Route path='/check' /* exact */ component={CheckPage}></Route>
                <Route path='/issue' exact component={Issue}></Route>
                {
                  userInfo.get('type') === 1 ?<Redirect to="/dataOverview" />:<Redirect to="/assign" />
                }
              </Switch>
            </div>
          }
          <div className="login-page">
            <Route path='/login' exact component={Login}></Route>
            <Route path='/updatePassword' exact component={UpdatePassword}></Route>
          </div>
        </>
      </BrowserRouter>
      <MLoading />
        {/* 账号过期提醒弹窗 */}
        <MModalTips
          title="账号冻结提醒" 
          okText='我知道了'
          cancelText='不再提醒'
          confirm={()=>setExpired(false)}
          cancel={hideModal}
          visible={isShowExpired}
          content={frozenMsg}
        ></MModalTips>
    </>
  )
}

const mapState = (state) => ({
  isLogin: state.getIn(['login', 'isLogin']),
  userInfo: state.getIn(['login', 'userInfo']),
  isShowExpired:state.getIn(['login','isShowExpired']),
  frozenMsg:state.getIn(['login','modalMsg']),
  taskId:state.getIn(['assign','taskId'])
})
const mapDispatch = (dispatch) => ({
	setExpired(bool){
    dispatch(actionCreators.changeExpiredModalVal(bool))
  }
})

export default connect(mapState,mapDispatch)(Layout)