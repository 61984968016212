
import * as constants from './constants';
import * as services from '../../../services/class'
import {  message } from 'antd'
import { fromJS } from 'immutable'
import { showToast } from '../../../utils/toast';

const changeClassList = (data) => ({
  type: constants.CHANGE_CLASSLIST,
  data
})

export const changeGroupData = (data) => ({
  type: constants.CHANGE_CLASSGROUP,
  data: fromJS(data)
})


const changeCurrClassId = value => ({
  type: constants.CHANGE_CURRCLASSID,
  value
})

const changeCurrClassGroupId = value => ({
  type: constants.CHANGE_CURRCLASSGROUPID,
  value
})

export const changeCurrGroupId = value => ({
  type: constants.CHANGE_CURRCLASSGROUPID,
  value
})
// export const changeGroupDataChecked = (val) => {
//   dispatch(changeGroupData(val))
// }

export const getClassList = () => {
  return async dispatch => {
    const res = await services.queryClassList()
    dispatch(changeClassList(res.data))
    dispatch(changeCurrClassId(res.data[0].id))
    const res1 = await services.queryClassGroup(res.data[0].id)
    dispatch(changeGroupData(res1?.data))
    dispatch(changeCurrClassGroupId(''))
    const data = {
      page: 1,
      size: 10,
      classId:res.data[0].id,
      groupId: ''
    }
    const res2 = await services.queryStudentList(data)
    if(res2.code === 0) {
      dispatch(changeStudentList(res2.data))
    }
  }
}


export const getClassGroupList = (classId) => {
  return async dispatch => {
    const res = await services.queryClassGroup(classId)
    dispatch(changeCurrClassId(classId))
    const temp =res.data.groupList.map(item => ({ ...item, checked: false }));
    console.log("temp>>>", temp)
    let obj = {
      groupList: temp,
      studentNum: res.data.studentNum
    }
    dispatch(changeGroupData(obj))
    dispatch(changeCurrClassGroupId(''))
    const data = {
      page: 1,
      size: 10,
      classId: classId,
      groupId: ''
    }
    const res1 = await services.queryStudentList(data)
    if(res1.code === 0) {
      dispatch(changeStudentList(res1.data))
    }
  }
}

export const createClassItem = (data, callback) => {
  return async dispatch => {
    const res = await services.createNewClass(data)
    if(res.code===0){
      showToast({
        type: 'success',
        content: '创建班级成功'
      })
      callback && callback();
      const result = await services.queryClassList()
      dispatch(changeClassList(result.data))
      dispatch(changeCurrClassId(result.data[0].id))
    } else {
      showToast({
        type: 'error',
        content: res.msg || '创建班级失败'
      })
    }
  } 
}

export const createClassGroupItem = (classId, name) => {
  return async dispatch => {
    const res = await services.createClassGroup(classId, name)
    if(res.code===0){
      message.success(res.msg, 1.5)
      const result = await services.queryClassGroup(classId)
      dispatch(changeGroupData(result.data))
      dispatch(changeCurrClassGroupId(result?.data?.groupList[0]?.id))
    } else {
      message.error(res.msg, 1.5)
    }
  } 
}

 
const changeStudentList = data => ({
  type: constants.CHANGE_STUDENTDATA,
  data
})
const changeAllStudentList = data => ({
  type: constants.CHANGE_AllSTUDENTDATA,
  data
})
export const getStudentData = (data) => {
  return async dispatch => {
    const res = await services.queryStudentList(data)
    if(res.code === 0) {
      // 这里怎么拿到groupList的值，拿到check的值
      // dispath改变SelectedRowKeys的值
     
      dispatch(changeStudentList(res.data))
    }
    let obj = {
      page: 1,
      size: 1000,
      classId: data.classId,
      groupId: data.groupId,
      noGroup: data.noGroup
    }
    const res2 = await services.queryStudentList(obj)
    console.log("res2>>>", res2, data)
    if(res2.code === 0) {
      if(data.isExport === false) {
        dispatch(setSelectedRowKeys([]))
        dispatch(changeStudentList(res.data))
        return
      }
      const val = res.data.rows?.map(v => v.studentId)
      console.log("111199999>>>", 111199999)
      if(data.allChecked) {
        dispatch(setSelectedRowKeys(val))
      }
      if(data.checked && data.checked === true) {
        console.log("res.data>>>", res.data)
        if(data.sKeys) {
          let result = data.sKeys.some(item => val.includes(item))
          if(!result) {
            data.sKeys.push(...val)
          }
        }
        dispatch(setSelectedRowKeys(data.sKeys))
      } else if(data && data.checked === false) {
        // false的话，要去掉val
        // data.sKeys = data.sKeys.filter(item => !val.includes(item))
        dispatch(setSelectedRowKeys(data.sKeys))
      }
      dispatch(changeAllStudentList(res2.data.rows))
    }
  } 
}


export const creatStudent = (data, createCallBack) => {
  return async dispatch => {
    const res = await services.createStudent(data);
    if(res.code===0) {
      showToast({
        type: 'success',
        content: '已成功创建账号'
      })
      createCallBack && createCallBack();
      const _data = {
        page: 1, size: 10,
        classId: data.classId,
        groupId: data.groupId
      }
      const res2 = await services.queryClassGroup(data.classId)
      dispatch(changeGroupData(res2.data))
      const res = await services.queryStudentList(_data)
      if(res.code === 0) {
        dispatch(changeStudentList(res.data))
      }
    } else {
      showToast({
        type: 'error',
        content: res.msg || '创建失败，请检查网络后重新操作'
      })
    }
  } 
}


export const createStudentNumber = (data) => {
  return async dispatch => {
    const _data = {
      id:data.id,
      account:data.num
    }
    const res = await services.updateStudent(_data)
    message[res.code===0?'success': 'error']('添加学号成功', 1.5)

    const _data2 = {
      page: 1, size: 10,
      classId: data.classId,
      groupId: data.groupId
    }
    const res1 = await services.queryStudentList(_data2)
      if(res1.code === 0) {
        dispatch(changeStudentList(res1.data))
      }
  } 
}

export const changeStudentGroup = (data, currentPage) => {
  return async dispatch => {
    const _data = {
      id: data.id,
      groupId: data.groupId,
      classId: data.classId
    }
    const res = await services.updateStudent(_data)
    message[res.code===0?'success': 'error']('设置分组成功', 1.5)
    const _data1 = {
      page: currentPage, size: 10,
      classId: data.classId,
      groupId: data.cGroupId,
      noGroup: data.noGroup
    }
    const res2 = await services.queryClassGroup( data.classId)
    dispatch(changeGroupData(res2.data))
    // dispatch(changeCurrClassGroupId(''))
    const res1 = await services.queryStudentList(_data1)
      if(res1.code === 0) {
        dispatch(changeStudentList(res1.data))
      }
  } 
}

export const deleteGroupItem = (id, classId) => {
  
  return async dispatch => {
    const res = await services.deleteGroup(id)
    message[res.code===0?'success': 'error'](res.msg, 1.5)
    if(res.code===0) {
      const res1 = await services.queryClassGroup(classId)
     if(res1.code===0){
      dispatch(changeGroupData(res1.data))
      dispatch(changeCurrClassGroupId(''))
        const _data = {
          page: 1, size: 10,
          classId: classId,
          groupId: ''
        }
        const res = await services.queryStudentList(_data)
        if(res.code === 0) {
          dispatch(changeStudentList(res.data))
        }
      }
    }
  } 
}

export const updateGroupName = (id, name, classId) => {
  return async dispatch => {
    const res = await services.updateGroupName(id, name)
    message[res.code===0?'success': 'error'](res.msg, 1.5)
    if(res.code===0) {
      const res = await services.queryClassGroup(classId)
      if(res.code===0){
        dispatch(changeGroupData(res.data))
      }
    }

  }
}

export const updateStudentGroup = (sids, gid, classId, currPage, currClassGroupId, isNoGroup) => {
  return async dispatch => {
    const res = await services.updateStudentsToGroup(sids, gid)
    message[res.code===0?'success': 'error'](res.msg, 1.5)
    if(res.code===0) {
      const res = await services.queryClassGroup(classId)
      if(res.code===0){
        dispatch(changeGroupData(res.data))
        const _data = {
          page: currPage, size: 10,
          classId,
          groupId: currClassGroupId,
          noGroup: isNoGroup ? 1 : 0
        }
        const res1 = await services.queryStudentList(_data)
        dispatch(changeStudentList([]));
        dispatch(changeStudentList(res1.data))
      }
    }
  }
}

export const updateStudentClass = (sids, cid, classId, currPage, currClassGroupId, isNoGroup) => {
  console.log("99999>>>", sids, cid)
  return async dispatch => {
    const res = await services.updateStudentsToClass(sids, cid)
    message[res.code===0?'success': 'error'](res.msg, 1.5)
    if(res.code===0) {
      const res = await services.queryClassGroup(classId)
      if(res.code===0){
        dispatch(changeGroupData(res.data))
        const _data = {
          page: currPage, size: 10,
          classId,
          groupId: currClassGroupId,
          noGroup: isNoGroup ? 1 : 0
        }
        const res1 = await services.queryStudentList(_data)
        dispatch(changeStudentList([]));
        dispatch(changeStudentList(res1.data))
      }
    }
  }
}

export const deleteClass = id => {
  return async dispatch => {
    const res = await services.deleteClassById(id)
    if(res.code===0) {
      showToast({
        type: 'success',
        content: res.msg || '删除成功'
      })
      const res0 = await services.queryClassList();
      console.log(res0);
      dispatch(changeClassList(res0.data))
      dispatch(changeCurrClassId(res0.data[0].id))
      const res1 = await services.queryClassGroup(res0.data[0].id)
      dispatch(changeGroupData(res1?.data))
      dispatch(changeCurrClassGroupId(''))
      const data = {
        page: 1,
        size: 10,
        classId: res0.data[0].id,
        groupId: ''
      }
      const res2 = await services.queryStudentList(data)
      if(res2.code === 0) {
        dispatch(changeStudentList(res2.data))
      }
    } else {
      showToast({
        type: 'error',
        content: res.msg || '删除失败'
      })
    }
  }
}

export const changeClassName = (name, id) => {
  return async dispatch => {
    const res = await services.changeClassName(name, id)
    message[res.code===0?'success': 'error'](res.msg, 1.5)
    if(res.code===0) {
      const res = await services.queryClassList()
      dispatch(changeClassList(res.data))
      
     
    }
  }
}

export const editClassMsg = (data, callback) => {
  return async dispatch => {
    const res = await services.editClassById(data);
    if(res.code===0) {
      showToast({
        type: 'success',
        content: '修改班级成功'
      });
      callback && callback();
      const res1 = await services.queryClassList()
      dispatch(changeClassList(res1.data))
    } else {
      showToast({
        type: 'error',
        content: res.msg ||'修改失败，请稍后重试'
      })
    }
  }
}

const changeAnExcelData = data =>({
  type: constants.CHANGE_ANEXCELDATA,
  data
}) 

export const uploadExcel = (data) => {
  return async dispatch => {
    dispatch(changeAnExcelData([]))
    const res = await services.downloadData('fzdTeacherStudent/analysisExcel', data)
    dispatch(changeAnExcelData(res.data))
  }
}

const changeTeacherList = data =>({
  type: constants.CHANGE_TEACHERLIST,
  data
})

export const getAllTeacher = (name) => {
  return async dispatch => {
    const res = await services.getAllTeacher(name)
    dispatch(changeTeacherList(res.data))
  }
}

export const transferClass = (classId, teacherId) => {
  return async dispatch => {
    const res = await services.transferClass(classId, teacherId)
    message[res.code===0?'success': 'error'](res.msg, 1.5)
    if(res.code === 0) {
      const res = await services.queryClassList()
      dispatch(changeClassList(res.data))
      dispatch(changeCurrClassId(res.data[0].id))
      const res1 = await services.queryClassGroup(res.data[0].id)
      dispatch(changeGroupData(res1?.data))
      dispatch(changeCurrClassGroupId(''))
      const data = {
        page: 1,
        size: 10,
        classId:res.data[0].id,
        groupId: ''
      }
      const res2 = await services.queryStudentList(data)
      if(res2.code === 0) {
        dispatch(changeStudentList(res2.data))
      }
    }
  }
}


export const getImportExcelEmplate = () => {
  return async dispatch => {
    const res = await services.getImportExcelEmplate()
    const blob = new Blob([res])
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
　　downloadElement.href = href;
　　downloadElement.download = '模板.xlsx'
　　document.body.appendChild(downloadElement)
　　downloadElement.click()
　　document.body.removeChild(downloadElement)
　　window.URL.revokeObjectURL(href)
  }
}



export const importStudents = (list, classId, groupId, excelData) => {
  return async dispatch => {
    let arr = []
    let arr2 = excelData
    for(const i in list) {
      const data = {
        classId,
        groupId,
        name: list[i].name,
        phone: list[i].phone
      }
      const res = await services.createStudent(data)
      arr.push({ 
        name: list[i].name,
        phone: list[i].phone,
        result: res.msg
      })
    }

    for(const i in arr2) {
      for(const j in arr) {
        if(arr2[i].phone === arr[j].phone) {
          arr2[i].result = arr[j].result
        }
      }
    }
    dispatch(changeAnExcelData(arr2))

    const res2 = await services.queryStudentList({
      page: 1,
      size: 10,
      classId,
      groupId
    })
    if(res2.code === 0) {
      dispatch(changeStudentList(res2.data))
    }
  }
} 

export const exportStudents = (data) => {
  console.log("data8888>>>", data)
  return async dispatch => {
    const res = await services.exportStudents(data)
    const blob = new Blob([res])
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
　　downloadElement.href = href;
　　downloadElement.download = '列表.xlsx'
　　document.body.appendChild(downloadElement)
　　downloadElement.click()
　　document.body.removeChild(downloadElement)
　　window.URL.revokeObjectURL(href)
  }
} 
 
const changeisNoGroup = data => ({
  type: constants.CHANGE_ISNOGROUP,
  data
})
export const setIsNoGroup = (data) => {
  return async dispatch => {
    dispatch(changeisNoGroup(data));
  }
}

const changeIsExport = data => ({
  type: constants.CHANGE_ISEXPORT,
  data
})

export const setIsExport = (data) => {
  return async dispatch => {
    dispatch(changeIsExport(data));
  }
}

const changeAllChecked = data => ({
  type: constants.CHANGE_ALLCHECKED,
  data
})

export const setAllChecked = (data) => {
  return async dispatch => {
    dispatch(changeAllChecked(data));
  }
}


const changeSelectedRowKeys = data =>({
  type: constants.CHANGE_SELECTEDROWKEYS,
  data
})

export const setSelectedRowKeys = (data) => {
  return async dispatch => {
    dispatch(changeSelectedRowKeys(data))
  }
}