/* 
type: 
  wrong2: 错题列表，显示echarts，正确人数，错误人数
  right： 选项正确答案高亮
  wrong： 选项错误答案高亮
  sys: 系统题库列表，展示涉及知识点，难度，添加题库按钮，添加布置，移除布置按钮， 正确选项高亮
  personal: 我的题库列表，展示涉及知识点，难度，编辑，删除，添加布置，移除布置按钮， 正确选项高亮
  task: 任务题目列表， 展示难度，移除布置按钮，正确选项高亮
*/

import { connect } from 'react-redux';
import { Fragment, useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom'
import { Collapse,Button } from 'antd'
import { actionCreators } from '../../pages/arrangement/store'
import { getQuestionList } from '../../pages/assign/store/actionCreator';
import { CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons'

import TaskEcharts from '../taskEcharts';

import { QuestionDetailContainer, TopContainer, QuestionType, QuestionContent, OptionImg, ItemImg, ItemOptions, ItemOption, ItemToolBar, ItemToolBarCon, ItemToolBarConLeft, ColorBox, ItemToolBarConRight, DropdownBtn, Answer, QuestionContainer, /* TimeContainer, ColorContainer, */ RightNum, WrongNum, EchartsContainer, BlackButton, AlreadyAdd, AddOrRemeveToTask, SysIcon, AssignCount } from './style';
import { queryQuestionWrongCharts } from '../../services/check';
import { joinTask, removeTaskItem } from '../../services/media';
import { DIFFICULTY } from '../../pages/assign/list/question/constant';

import * as assignAction from '../../pages/assign/store/actionCreator';
import { showToast } from '../../utils/toast';

const { Panel } = Collapse;

const QuestionItem = (props) => {
  const { data, /* time=false, */ type='normal', number, ignorePreFlag=0, isJoin=0, page=1, source=0, level='', title='', questionType, AddToMyQuestion, curTextBookId, curSelectId, delQuestion, history, index, joinTaskCallBack, removeTaskCallBack, getTaskCount,taskId } = props;
  const [nums, setNums] = useState({})

  // 正确高亮选项
  let rightHightLight = ['right', 'sys', 'personal', 'task'];
  // 错误高亮选项
  let wrongHightLight = ['wrong'];
  // 系统标签
  let sysIcon = ['personal', 'task'];
  // 添加布置，删除布置按钮
  let assignBtn = ['sys', 'personal'];
  // 难度
  let levels = ['sys', 'personal', 'task'];
  // echarts
  let showEcharts = useMemo(() => ['wrong2'], []);
  // 正确人数，错误人数
  let personNum = ['wrong2'];
  // 编辑、删除按钮
  let editDeleteBtn = ['personal'];
  // 添加题库按钮
  let addQuestionBtn = ['sys'];
  // 删除布置按钮
  let removeAssignBtn = ['task'];
  // 布置次数
  let taskCount = ['sys', 'personal', 'task'];


  const handleAddBtnClick = async id => {
    const res = await joinTask(3, id, curTextBookId);
    props.setCurSelectedAssignId(props.curSelectId)
    if(res.code === 0) {
      getTaskCount();
      showToast({
        type: 'success',
        content: '加入布置成功'
      })
      if(joinTaskCallBack) {
        // 加入任务成功后需要外部做的事
        joinTaskCallBack(index)
      }
    } else {
      showToast({
        type: 'success',
        content: '加入布置失败，请稍后重试'
      })
    }
  }
  const handleRemoveBtnClick = async pid => {
    const res = await removeTaskItem(pid);
    if(res) {
      getTaskCount();
      showToast({
        type: 'success',
        content: '移除布置成功'
      })
      if(removeTaskCallBack) {
        // 删除后需要外部做的事
        removeTaskCallBack(index);
      }
    } else {
      showToast({
        type: 'error',
        content: '移除布置失败，请稍后重试'
      })
    }
  }

  // 添加到我的题库
  const toAdd = () => {
    let d1 = {
      questionId: data.id
    }
    let d2 = {
      page,
      ignorePreFlag,
      source,
      size: 10,
      textbookId: curTextBookId,
      textbookSonCatId: curSelectId,
    }
    title && (d2.title = title)
    level && (d2.level = level)
    questionType && (d2.type = questionType)
    AddToMyQuestion(d1, d2)
  }
  // 从题库中删除
  const toDelete = () => {
    delQuestion(data.id);
  }

  // 编辑题目
  const toEdit = () => {
    history.push(`/assign/newQuestion/${data.id}`)
  }

  useEffect(() => {
    (async function() {
      // 错题页获取echarts数据
      if(showEcharts.includes(type)) {
        let params = {
          taskId: data.taskId,
          questionId: data.questionId
        }
        let res = await queryQuestionWrongCharts(params);
        setNums({
          studentSubmitSum: res.data.correct,
          errors: res.data.errors,
          studentSum: res.data.correct + res.data.errors
        })
      }
    })()
  }, [data, showEcharts, type])

  const [activeKey, setActiveKey] = useState('0')
  const changeActiveKey =() => {
    activeKey === '0'? setActiveKey('1') : setActiveKey('0')
  }
  const [isAnswer,setIsAnswer] = useState(false)
  const isShowAnswer = ()=>{
    setIsAnswer(!isAnswer)
  }
  return (
    <QuestionDetailContainer>
      <TopContainer className={['wrong2'].includes(type) ?'jcstart':''}>
        {
          data && 
          <QuestionContainer>
            <QuestionType>
              {
                data.source == 0 && sysIcon.includes(type)  ? <SysIcon>系统</SysIcon> : ''
              }
              {
                number ? <>{number}.</> : ''
              }
              {(data.questionType||data.type) == 1 ? '选择题' : '填空题'}
            </QuestionType>
            <QuestionContent>
              {data.questionTitle || data.title}
              {/* {
                data.questionPic && <img src={data.questionPic} alt='image' />
              } */}
            </QuestionContent>

            {(data.questionPic || data.titlePic) && <ItemImg  src={data.questionPic || data.titlePic}></ItemImg>}
            <ItemOptions>
              {(data?.questionsOptionsList || data?.optionList)?.map(item=>{
                return(
                  <Fragment key={item.code}>
                    <ItemOption className={[rightHightLight.includes(type) && item.correctFlag && isAnswer?'right' : '', 
                    wrongHightLight.includes(type) && item.code === data.studentOptionCodesStr ? 'wrong' : '']}>{item.code + '. ' + item.content}
                    </ItemOption>
                    {item.image && <OptionImg src={item.image}></OptionImg>}
                  </Fragment>
                )
              })}
            </ItemOptions>
          </QuestionContainer>
        }

        {/* 答题用时 */}
        {/* {
          time && 
          <TimeContainer>
            答题用时：
            <ColorContainer>
              00:30:00
            </ColorContainer>
          </TimeContainer>
        } */}

        {/* 布置次数 */}
        {
          taskCount.includes(type) ? 
            <AssignCount>布置{data.taskCount}次</AssignCount> : ''
        }

        {/* 正确人数，错误人数，echarts */}
        {
          personNum.includes(type) && <RightNum>{nums.studentSubmitSum}</RightNum>
        }
        {
          personNum.includes(type) && <WrongNum>{nums.errors}</WrongNum>

        }
        {
          showEcharts.includes(type) ? 
          <EchartsContainer>
            <TaskEcharts identity={data.questionId} data={nums} type="question-b" width={330} height={150} />
          </EchartsContainer> : ''
        }
        
      </TopContainer>

        <ItemToolBar>
          <Collapse activeKey={activeKey} >
            <Panel showArrow={false}  key="1" collapsible={'disabled'} header={
              <ItemToolBarCon>
                <ItemToolBarConLeft>
                  {
                    levels.includes(type) ? 
                      <span style={{color: '#666666', fontSize: '14px'}}>
                        难度：
                        {
                          DIFFICULTY.find(item => item.value === data.level)?.label || '未知'
                        }
                      </span> : 
                      <>
                        正确答案：
                        <ColorBox>{data.questionAnswer || data.answerCodesStr}</ColorBox>
                      </>
                  }
                </ItemToolBarConLeft>
                <ItemToolBarConRight >
                  {
                    rightHightLight.includes(type) ? <Button onClick={isShowAnswer} type={isAnswer?'':'primary'}>{!isAnswer?'显示答案':'隐藏答案'}</Button>:<></>
                  }
 
                  <DropdownBtn onClick={changeActiveKey}>解析&nbsp;{activeKey==='0' ? <CaretDownOutlined />:<CaretUpOutlined />}</DropdownBtn>
                  {
                    addQuestionBtn.includes(type) ? 
                    (data.isMyQuestion ? <AlreadyAdd>已添加</AlreadyAdd> : <BlackButton onClick={toAdd}>添加题库</BlackButton>) : ''
                  }
                  {
                    editDeleteBtn.includes(type) ?
                    (data.source === 1 ? 
                      <>
                        <BlackButton onClick={toEdit}>编辑</BlackButton>
                        <BlackButton onClick={toDelete}>删除</BlackButton>
                      </> : 
                      <BlackButton onClick={toDelete}>移除</BlackButton>
                    ) : ''
                  }
                  {
                    assignBtn.includes(type) ? (
                      data.isReadyAdd ? 
                      <AddOrRemeveToTask
                        onClick={()=>{handleRemoveBtnClick(data.preId)}}>
                        移除布置
                      </AddOrRemeveToTask> : 
                      <AddOrRemeveToTask
                        onClick={()=>{handleAddBtnClick(data.id)}}>
                        加入布置
                      </AddOrRemeveToTask>
                      
                    ) : '' 
                  }
                  {
                    removeAssignBtn.includes(type) && !taskId ? 
                      <AddOrRemeveToTask
                        onClick={()=>{handleRemoveBtnClick(data.preId)}}>
                        移除布置
                      </AddOrRemeveToTask> : ''
                  }
                </ItemToolBarConRight>
              </ItemToolBarCon>
            }>
              <Answer>
                <div >答案解析：<span>{data.answerAnalysis}</span>
                <div >{data.answerPic&&<ItemImg  src={data.answerPic}></ItemImg>}</div>
                </div> 
              </Answer>
            </Panel>
          </Collapse> 
        </ItemToolBar>
    </QuestionDetailContainer>
  )
}
const mapState = (state) => ({
  curTextBookId: state.getIn(['assign', 'curTextBookId']),
  curSelectId: state.getIn(['assign', 'curSelectId']),
  pptTaskNum: state.getIn(['assign', 'pptTaskNum']),
  videoTaskNum: state.getIn(['assign', 'videoTaskNum']),
  questionTaskNum: state.getIn(['assign', 'questionTaskNum']),
})

const mapDispatch = (dispatch) => ({
  joinTask(id, textBookId, data){
    dispatch(actionCreators.joinTask('question', id, textBookId))
   setTimeout(() => {
    dispatch(getQuestionList(data))
   }, 300);
  },
  removeTask(pid, data) {
    dispatch(actionCreators.removeTaskItem('question', pid))
    setTimeout(() => {
      dispatch(getQuestionList(data))
     }, 300);

  },
  getTaskCount() {
    dispatch(assignAction.getTaskCount())
  },
  AddToMyQuestion(data, data2) {
    dispatch(assignAction.AddToMyQuestion(data, data2))
  },
  setCurSelectedAssignId(val) {
    dispatch(assignAction.setCurSelectedAssignId(val))
  },
})
export default withRouter(connect(mapState, mapDispatch)(QuestionItem));