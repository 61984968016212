
import * as constants from './constants';
import { fromJS } from 'immutable'
const defaultState = fromJS({
  list: {}
})

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  if (action.type === constants.CHANGE_QUESTIONLIST) {
   return state.set('list', action.data)
  }
  return state
}