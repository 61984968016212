export const CHANGE_COUNTDOWNNUM = 'login/CHANGE_COUNTDOWNNUM';
export const CHANGE_LOGIN = 'login/CHANGE_LOGIN';
export const CHANGE_USERINFO = 'login/CHANGE_USERINFO';
export const CHANGE_UPDATEPSWSTATE = 'login/CHANGE_UPDATEPSWSTATE';
export const CHANGE_ISSHOWEXPIRED = 'login/CHANGE_ISSHOWEXPIRED';
export const CHANGE_MSG = 'login/CHANGE_MSG';




