import { useEffect, useCallback, memo } from 'react';

// 使用echarts
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

const TaskEcharts = (props) => {
  let { data, identity=0, width=100, height=100, type='video', radius=['40%', '65%'], textFontSize=14, subTextFontSize=12 } = props
  
  let color;
  let top= '45%';
  let showEmphasis = true;
  let showLabel = true;
  let result;
  let labelStyle;
  let unit="人";

  if(['video', 'videolist', 'courseware', 'coursewarelist'].includes(type)) {
    labelStyle = [
      {
        name: '已观看',
        icon: 'circle',
      },
      {
        name: '未观看',
        icon: 'circle',
      }
    ]
    result = [
      {value: data?.studentSubmitSum || 0, name: '已观看'},
      {value: data?.studentSum - data?.studentSubmitSum || 0, name: '未观看'},
    ]
  } else if(['question-b', 'grade', 'gradedetail'].includes(type)) {
    labelStyle = [
      {
        name: '正确',
        icon: 'circle',
      },
      {
        name: '错误',
        icon: 'circle',
      }
    ]
    result=[
      {value: data?.studentSubmitSum || data?.correct || 0, name: '正确'},
      {value: data?.studentSum - data?.studentSubmitSum || data?.errors|| 0, name: '错误'},
    ]
  }

  if(type === 'video') {
    color= ['#46A1FD', '#F3677C'];
  } else if(type === 'videolist') {
    color = ['#46A1FD', '#F3677C'];
    radius = ['50%', '85%'];
    top = '40%';
    showLabel = false;
    showEmphasis = false;
  } else if(type === 'courseware') {
    color = ['#59CA75', '#F3677C'];
  } else if(type === 'coursewarelist') {
    color = ['#59CA75', '#F3677C'];
    radius=['50%', '85%'];
    top = '40%';
    showLabel = false;
  } else if(type === 'question') {
    color= ['#4ECACC', '#F3677C'];
    labelStyle = [
      {
        name: '已完成',
        icon: 'circle',
      },
      {
        name: '未完成',
        icon: 'circle',
      }
    ]
    result = [
      {value: data?.completed || 0, name: '已完成'},
      {value: data?.unfinished || 0, name: '未完成'},
    ]
  } else if(type === 'question-b'){
    color= ['#4ECACC', '#F3677C'];
  } else if(type === 'grade') {
    color=['#4ECACC', '#F3677C', '#F9D249'];
    radius=['50%', '75%'];
    top = '40%';
    // showEmphasis = false;
    showLabel = false;
    unit="道"
  } else if(type === 'gradedetail') {
    color=['#4ECACC', '#F3677C', '#F9D249'];
    radius=['45%', '80%'];
    unit="道"
  }

  useEffect(() => {

    const chartDom = document.getElementById(`echarts${identity}${type}`);
    var option;
    option = option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        right: '5%',
        top: 'center',
        orient: 'vertical',
        itemHeight: 8,
        data: labelStyle,
      },
      title: {
        text: data?.studentSum ||data?.completed+data?.unfinished  || 0,
        subtext: '总数',
        textStyle: {
          fontWeight: 400,
          fontSize: `${textFontSize}px`,
          lineHeight: '1',
        },
        subtextStyle: {
          fontSize: `${subTextFontSize}px`,
          lineHeight: 1,
        },
        itemGap: 2,
        top,
        left: 'center'
      },
      color: color,
      series: [
        {
          name: '详情',
          type: 'pie',
          radius: radius,
          avoidLabelOverlap: false,
          label: {
            show: showLabel,
            position: 'outer',
            formatter: [`{a|{c}${unit}}`].join('\n'),
            rich: {
              a: {
                color: '#666666',
                fontSize: '12px'
              }
            }
          },
          emphasis: {
            label: {
              show: showEmphasis,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true,
            length: 11,
            length2: 0,
            // smooth: 1,
            lineStyle: {
              color: '#999'
            }
          },
          data: result
        }
      ]
    };
    const myChart = echarts.init(chartDom);
    option && myChart.setOption(option);
    return () => {
      // chartDom.removeAttribute('_echarts_instance_');
      myChart.dispose();
    }
  // eslint-disable-next-line
  },[data])

  const clickEacharts = useCallback((e) => {
    e.stopPropagation();
  }, [])

  return (
    <div onClick={(e)=>{clickEacharts(e)}} style={{width: width+'px', height: height + 'px'}} id={`echarts${identity}${type}`}></div>
  )
}

export default memo(TaskEcharts);