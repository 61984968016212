export const CHANGE_CLASSLIST = 'class/change_class_list'
export const ADD_CLASSITEM = 'class/add_class_item'
export const CHANGE_CURRCLASSID = 'class/change_curr_class_id'
export const CHANGE_CLASSGROUP = 'class/change_class_group_list'
export const CHANGE_CURRCLASSGROUPID = 'class/change_curr_class_group_id'
export const CHANGE_STUDENTDATA = 'class/change_student_data'
export const CHANGE_AllSTUDENTDATA = 'class/change_allstudent_data'
export const CHANGE_SEARCHDATA = 'class/change_search_data'
export const CHANGE_TEACHERLIST = 'class/change_teacher_list'
export const CHANGE_ANEXCELDATA = 'class/change_analysis_excel_data'
export const CHANGE_ISNOGROUP = 'class/change_isnogroup'
export const CHANGE_ISEXPORT = 'class/change_isexport'
export const CHANGE_ALLCHECKED = 'class/change_allchecked'
export const CHANGE_SELECTEDROWKEYS = 'class/change_selectedRowKeys'
export const CHANGE_CHOSEROWSINCURGROUP = 'class/change_choseRowsInCurGroup'


