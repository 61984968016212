import * as constants from './constants';
import * as services from '../../../services/check'

// 修改tabs显示状态
export const changeTabsShow = (data) => ({
  type: constants.CHANGE_TABS_SHOW,
  data
})

// 修改视频任务列表
const changeVideoTaskListData = (data) => ({
  type: constants.CHANGE_VIDEO_TASK_LIST_DATA,
  data
})
// 修改单个任务视频列表
const changeVideoListData = (data) => ({
  type: constants.CHANGE_VIDEO_LIST_DATA,
  data
})

// 修改单个视频已观看学生列表
const changeVideoReadListData = (data) => ({
  type: constants.CHANGE_VIDEO_READ_LIST_DATA,
  data
})

// 修改单个视频未观看学生列表
const changeVideoUnReadListData = (data) => ({
  type: constants.CHANGE_VIDEO_UNREAD_LIST_DATA,
  data
})







// 修改课件任务列表
const changePPTTaskListData = (data) => ({
  type: constants.CHANGE_PPT_TASK_LIST_DATA,
  data
})
// 修改单个任务课件列表
const changePPTListData = (data) => ({
  type: constants.CHANGE_PPT_LIST_DATA,
  data
})

// 修改单个课件已观看学生列表
const changePPTReadListData = (data) => ({
  type: constants.CHANGE_PPT_READ_LIST_DATA,
  data
})

// 修改单个课件未观看学生列表
const changePPTUnReadListData = (data) => ({
  type: constants.CHANGE_PPT_UNREAD_LIST_DATA,
  data
})







// 修改问题任务列表
const changeQuestionTaskListData = (data) => ({
  type: constants.CHANGE_QUESTION_TASK_LIST_DATA,
  data
})

// 修改单个任务题目列表
const changeQuestionListData = (data) => ({
  type: constants.CHANGE_QUESTION_LIST_DATA,
  data
})

// 修改当前题目详情
const changeCurrentQuestionData = (data) => ({
  type: constants.CHANGE_CURRENT_QUESTION_DATA,
  data
})

// 修改当前题目详情
const changeQuestionRightListData = (data) => ({
  type: constants.CHANGE_QUESTION_RIGHT_LIST_DATA,
  data
})

// 修改当前题目详情
const changeQuestionWrongListData = (data) => ({
  type: constants.CHANGE_QUESTION_WRONG_LIST_DATA,
  data
})

// 修改当前题目成绩详情
const changeQuestionScoreListData = (data) => ({
  type: constants.CHANGE_QUESTION_SCORE_LIST_DATA,
  data
})

// 修改当前学生成绩详情
const changeCurrentStudentData = (data) => ({
  type: constants.CHANGE_CURRENT_STUDENT_DATA,
  data
})

// 修改当前学生成绩详情
const changeCorrectQuestionListData = (data) => ({
  type: constants.CHANGE_CORRECT_QUESTION_LIST_DATA,
  data
})

// 修改当前学生成绩详情
const changeWrongQuestionListData = (data) => ({
  type: constants.CHANGE_WRONG_QUESTION_LIST_DATA,
  data
})


// 修改任务错题列表
const changeTaskWrongQuestionListData = (data) => ({
  type: constants.CHANGE_TASK_WRONG_QUESTION_LIST_DATA,
  data
})







// 获取视频任务列表数据
export const getVideoTaskListData = (data) => {
  return async dispatch => {
    const res = await services.queryVideoTaskList(data)
    dispatch(changeVideoTaskListData(res.data))
  }
}

// 修改当前视频任务id
export const changeCurVideoTaskId = (data) => ({
  type: constants.CHANGE_CURRENT_VIDEO_ID,
  data
})

// 修改单个任务视频列表数据
export const getVideoListData = (data) => {
  return async dispatch => {
    const res = await services.queryVideoTaskInfo(data)
    dispatch(changeVideoListData(res.data))
  }
}

// 修改单个任务视频已观看未观看学生数据
export const getVideoReadOrUnReadList = (data) => {
  return async dispatch => {
    const res = await services.queryVideoTaskInfoStatus(data)
    if(data.submitFlag == 1) { // 已观看
      dispatch(changeVideoReadListData(res.data))
    } else { // 未观看
      dispatch(changeVideoUnReadListData(res.data))
    }
  }
}

// 获取视频任务列表图标信息
export const getVideoTaskCharts = async (data) =>  {
  return new Promise(async(resolve, reject) => {
    const res = await services.queryVideoTaskCharts(data)
    if(res.code === 0) {
      resolve(res.data)
    }
  })
  
}

// 获取检查-视频列表-视频详情右侧图表
export const getVideoTaskInfoCharts = async (data) =>  {
  return new Promise(async(resolve, reject) => {
    const res = await services.queryVideoTaskInfoCharts(data)
    if(res.code === 0) {
      resolve(res.data)
    }
  })
}






// 获取课件任务列表数据
export const getPPTTaskListData = (data) => {
  return async dispatch => {
    const res = await services.queryPPTTaskList(data)
    dispatch(changePPTTaskListData(res.data))
  }
}

// 修改单个任务课件列表数据
export const getPPTListData = (data) => {
  return async dispatch => {
    const res = await services.queryPPTTaskInfo(data)
    dispatch(changePPTListData(res.data))
  }
}

// 修改单个任务课件已观看未观看学生数据
export const getPPTReadOrUnReadList = (data) => {
  return async dispatch => {
    const res = await services.queryPPTTaskInfoStatus(data)
    if(data.submitFlag == 1) { // 已观看
      dispatch(changePPTReadListData(res.data))
    } else { // 未观看
      dispatch(changePPTUnReadListData(res.data))
    }
  }
}

// 获取课件任务列表图标信息
export const getPPTTaskCharts = async (data) =>  {
  return new Promise(async(resolve, reject) => {
    const res = await services.queryPPTTaskCharts(data)
    if(res.code === 0) {
      resolve(res.data)
    }
  })
  
}

// 获取检查-课件列表-课件详情右侧图表
export const getPPTTaskInfoCharts = async (data) =>  {
  return new Promise(async(resolve, reject) => {
    const res = await services.queryPPTTaskInfoCharts(data)
    if(res.code === 0) {
      resolve(res.data)
    }
  })
}














// 获取问题任务列表数据
export const getQuestionTaskListData = (data) => {
  return async dispatch => {
    const res = await services.queryQuestionTaskList(data) // 课件任务列表
    dispatch(changeQuestionTaskListData(res.data))

  }
}


// 获取课件任务列表图标信息
export const getQuestionTaskCharts = async (data) =>  {
  return new Promise(async(resolve, reject) => {
    const res = await services.queryQuestionTaskCharts(data) // 视频详情
    if(res.code === 0) {
      resolve(res.data)
    }
  })
}

// 修改单个任务题目列表数据
export const getQuestionListData = (data) => {
  return async dispatch => {
    const res = await services.queryQuestionTaskInfo(data) // 视频详情
    dispatch(changeQuestionListData(res.data))
  }
}

// 分页获取单条问题数据
export const getCurrentQuestionData = (data) => {
  return async dispatch => {
    const res = await services.queryQuestionTaskInfo(data) // 视频详情
    dispatch(changeCurrentQuestionData(res.data))

    // 获取第一页学生正确错误列表
    let params1 = {
      page: 1,
      size: 10,
      taskId: data.taskId,
      questionId: res.data.rows[0].questionId,
      submitFlag: 1
    }
    const res1 = await services.queryQuestionToStudentInfo(params1)
    dispatch(changeQuestionRightListData(res1.data))
    let params2 = {
      page: 1,
      size: 10,
      taskId: data.taskId,
      questionId: res.data.rows[0].questionId,
      submitFlag: 2
    }
    const res2 = await services.queryQuestionToStudentInfo(params2)
    dispatch(changeQuestionWrongListData(res2.data))
  }
}

// 修改题对人信息
export const getQuestionRightOrWrongListData = (data) => {
  return async dispatch => {
    const res = await services.queryQuestionToStudentInfo(data) // 视频详情
    if(data.submitFlag == 1) {
      dispatch(changeQuestionRightListData(res.data))
    } else {
      dispatch(changeQuestionWrongListData(res.data))
    }
  }
}

// 修改题目成绩列表信息
export const getQuestionScoreListData = (data) => {
  return async dispatch => {
    const res = await services.queryQuestionScore(data) // 视频详情
    dispatch(changeQuestionScoreListData(res.data))
  }
}

// 分页获取单个学生数据
export const getCurrentStudentData = (data) => {
  return async dispatch => {
    const res = await services.queryQuestionScore(data) // 视频详情
    dispatch(changeCurrentStudentData(res.data))
    // 获取改学生第一页问题列表数据
      let params = {
        page: 1,
        size: 10,
        taskId: data.taskId,
        studentId: res.data.rows[0].studentId,
        submitFlag: 1
      }
      const res1 = await services.queryStudentToQuestionInfo(params)
      dispatch(changeCorrectQuestionListData(res1.data))
      let params2 = {
        page: 1,
        size: 10,
        taskId: data.taskId,
        studentId: res.data.rows[0].studentId,
        submitFlag: 2
      }
      const res2 = await services.queryStudentToQuestionInfo(params2)
      dispatch(changeWrongQuestionListData(res2.data))
  }
}



// 修改人对题信息
export const getCorrectOrWrongQuestionListData = (data) => {
  return async dispatch => {
    const res = await services.queryStudentToQuestionInfo(data) // 视频详情
    if(data.submitFlag == 1) {
      dispatch(changeCorrectQuestionListData(res.data))
    } else {
      dispatch(changeWrongQuestionListData(res.data))
    }
    return true
  }
}

// 获取任务错题列表
export const getTaskWrongQuestionData = (data) => {
  return async dispatch => {
    const res = await services.queryQuestionWrong(data) // 视频详情
    dispatch(changeTaskWrongQuestionListData(res.data))    
  }
}

// 修改tab显示隐藏
export const setTabsStatus = (status) => {
  return dispatch => {
    dispatch(changeTabsShow(status))
  }
}