export const CHANGE_TABS_SHOW = 'check/change_tabs_show'

export const CHANGE_VIDEO_TASK_LIST_DATA = 'check/change_video_task_list_data'
export const CHANGE_CURRENT_VIDEO_ID = 'check/change_current_video_id'
export const CHANGE_VIDEO_LIST_DATA = 'check/change_video_list_data'
export const CHANGE_VIDEO_READ_LIST_DATA = 'check/change_video_read_list_data'
export const CHANGE_VIDEO_UNREAD_LIST_DATA = 'check/change_video_unread_list_data'




export const CHANGE_PPT_TASK_LIST_DATA = 'check/change_ppt_task_list_data'
export const CHANGE_PPT_LIST_DATA = 'check/change_ppt_list_data'
export const CHANGE_PPT_READ_LIST_DATA = 'check/change_ppt_read_list_data'
export const CHANGE_PPT_UNREAD_LIST_DATA = 'check/change_ppt_unread_list_data'




export const CHANGE_QUESTION_TASK_LIST_DATA = 'check/change_question_task_list_data'
export const CHANGE_QUESTION_LIST_DATA = 'check/change_question_list_data'
export const CHANGE_CURRENT_QUESTION_DATA = 'check/change_current_question_data'
export const CHANGE_QUESTION_RIGHT_LIST_DATA = 'check/change_question_right_list_data'
export const CHANGE_QUESTION_WRONG_LIST_DATA = 'check/change_question_wrong_list_data'
export const CHANGE_QUESTION_SCORE_LIST_DATA = 'check/change_question_score_list_data'
export const CHANGE_CURRENT_STUDENT_DATA = 'check/change_current_student_data'
export const CHANGE_CORRECT_QUESTION_LIST_DATA = 'check/change_correct_question_list_data'
export const CHANGE_WRONG_QUESTION_LIST_DATA = 'check/change_wrong_question_list_data'
export const CHANGE_TASK_WRONG_QUESTION_LIST_DATA = 'check/change_task_wrong_question_list_data'

