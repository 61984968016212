/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-20 11:12:36
 * @LastEditTime: 2022-03-31 16:17:38
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import { request } from '../utils/request'

const POST = 'POST';
const GET = 'GET';
const DELETE = 'DELETE';
const PUT = 'PUT'

// 获取当前教材信息
export function getCurTextBook (params) {
  return request({
    url: '/fzdTextbook/queryByTextBookId',
    method: GET,
    params
  })
}

// 布置-题目-根据课本id获取课本系统题库或个人题库
export function getQuestionList (params) {
  return request({
    url: '/fzdTextbookQuestions/queryQuestionsByTextbookId',
    method: GET,
    params
  })
}

// (新增)布置-题目-将系统题库添加到我的题库
export function AddToMyQuestion (data) {
  return request({
    url: '/fzdTeacherQuestions/addToMyQuestion',
    method: POST,
    data
  })
}

// (新增)布置-题目-将题目从我的题库移除
export function removeMyQuestion (questionId) {
  return request({
    url: `/fzdTeacherQuestions/delMyQuestion/${questionId}`,
    method: DELETE
  })
}

// (新增)布置-题目-将题目从我的题库批量移除
export function BatchRemoveMyQuestion (data) {
  return request({
    url: `/fzdTeacherQuestions/delMyQuestion`,
    method: POST,
    data
  })
}

// (新增)布置-题目-教师创建个人题目
export function createQuestion (data) {
  return request({
    url: `/fzdTeacherQuestions/create`,
    method: POST,
    data
  })
}

// 上传图片
export function uploadFile(data) {
  return request({
    url: `/tencentUpload/uploadFile`,
    method: POST,
    data
  })
}

// (新增)布置-题目-教师修改个人题库
export function updateQuestion(data) {
  return request({
    url: `/fzdQuestions/update`,
    method: POST,
    data
  })
}

// (新增)布置-题目-根据题目ID获取教师可修改的题目信息
export function getQuesetionDetail(questionId) {
  return request({
    url: `/fzdQuestions/query/${questionId}`,
    method: GET,
  })
}


//获取添加教材教师可选年级
export function GradeSelection (){
  return request({
    url: `/fzdTeacherTextbook/grade`,
    method: GET,
  })
}

//(新增)布置-教材管理-获取添加教材教师可选学科
export function SubjectSelection (subjectId){
  return request({
    url: `/fzdTeacherTextbook/subject/${subjectId}`,
    method: GET,
  })
}

//获取添加教材教师可选教材版本
export function VersionSelection (params){
  return request({
    url: `/fzdTeacherTextbook/version/${params.gradeId}/${params.subjectId}`,
    method: GET,
  })
}

//获取添加教材教师可选课本
export function TextbookSelection (params){
  return request({
    url: `/fzdTeacherTextbook/textbook/${params.gradeId}/${params.subjectId}/${params.versionId}`,
    method: GET,
  })
}

//布置-教材管理-添加教材
export function addTextbookManage (data){
  return request({
    url: `/fzdTeacherTextbookManage/create`,
    method: POST,
    data
  })
}
//获取已添加教材列表
export function getTextbookList(params){
  return request({
    url: `/fzdTeacherTextbookManage/queryList`,
    method: GET,
    params
  })
}

//教材管理-设置默认教材
export function SetupDefaultTextBook(textbookManageId){
  return request({
    url: `/fzdTeacherTextbookManage/defaultTextBook/${textbookManageId}`,
    method: PUT,
  })
}

//教材管理-删除教材
export function deleteTextBook(textbookManageId){
  return request({
    url: `/fzdTeacherTextbookManage/delete/${textbookManageId}`,
    method: DELETE,
  })
}


//(新增)布置-教材管理-获取所有分类
export function getAllSubjuct(params){
  return request({
    url: `/fzdTeacherTextbookManage/querySubjectAll`,
    method: GET,
    params
  })
}

//(新增)学情跟踪-根据教材获取当前任务列表
export function getAllTask(textbookId){
  return request({
    url: `/fzdLearningTrack/taskAll/${textbookId}`,
    method: GET,
  })
}

//(新增)学情跟踪-根据任务获取任务图表
export function getTaskDetail(taskId){
  return request({
    url: `/fzdLearningTrack/taskChart/${taskId}`,
    method: GET,
  })
}

//(新增)学情跟踪-学习情况图表
export function getLearnDetail(taskId){
  return request({
    url: `/fzdLearningTrack/video/learnChart/${taskId}`,
    method: GET,
  })
}

//(新增)学情跟踪-学习情况-视频-学习时长排名（总观看时长）
export function getLearnDurationRankList(taskId){
  return request({
    url: `/fzdLearningTrack/video/learnDuration/${taskId}`,
    method: GET,
  })
}

//(新增)学情跟踪-学习情况-视频-最受欢迎视频排名（总播放时长）
export function getPopularRankList(taskId){
  return request({
    url: `/fzdLearningTrack/video/playDuration/${taskId}`,
    method: GET,
  })
}

//(新增)学情跟踪-学习情况-视频-完成情况列表查询
export function getCompleteDetailList(taskId){
  return request({
    url: `/fzdLearningTrack/completionList/${taskId}`,
    method: GET,
  })
}

//(新增)学情跟踪-学习情况-视频-完成情况列表导出
export function exportComplete(taskId){
  return request({
    url: `/fzdLearningTrack/completionList/export/${taskId}`,
    method: GET,
    responseType:'blob'
  })
}

//(新增)学情跟踪-学习情况-视频-完成情况明细列表导出
export function exportCompleteDetail(taskId){
  return request({
    url: `/fzdLearningTrack/video/completionDetailList/export/${taskId}`,
    method: GET,
    responseType:'blob'
  })
}

// (新增)学情跟踪-学习情况-课件图表
export function getPPTChart(taskId){
  return request({
    url: `/fzdLearningTrack/ppt/learnChart/${taskId}`,
    method: GET,
  })
}

// (新增)学情跟踪-学习情况-课件-最积极学员排名（最早完成观看）
export function getActiveRank(taskId){
  return request({
    url: `/fzdLearningTrack/ppt/learnActive/${taskId}`,
    method: GET,
  })
}

// (新增)学情跟踪-学习情况-课件-完成情况明细列表导出
export function exportPPTDetail(taskId){
  return request({
    url: `/fzdLearningTrack/ppt/completionDetailList/export/${taskId}`,
    method: GET,
    responseType:'blob'
  })
}

// (新增)学情跟踪-学习情况-题目-图表
export function getQuestionChart(taskId){
  return request({
    url: `/fzdLearningTrack/question/learnChart/${taskId}`,
    method: GET,
  })
}

// (新增)学情跟踪-学习情况-题目-错误率最高的题目
export function getWrongQuestionRankList(taskId){
  return request({
    url: `/fzdLearningTrack/question/errorRateToQuestion/${taskId}`,
    method: GET,
  })
}

// (新增)学情跟踪-学习情况-题目-错题数最多的学员
export function getWrongStudentRankList(taskId){
  return request({
    url: `/fzdLearningTrack/question/errorRateToStudent/${taskId}`,
    method: GET,
  })
}

// (新增)学情跟踪-学习情况-题目-完成情况明细导出
export function exportQuestionDetail(taskId){
  return request({
    url: `/fzdLearningTrack/question/completionDetailList/export/${taskId}`,
    method: GET,
    responseType:'blob'
  })
}

// (新增)学情跟踪-学习情况-视频/课件/题目-完成情况明细导出
export function exportDetail(taskId){
  return request({
    url: `/fzdLearningTrack/completionDetailList/export/${taskId}`,
    method: GET,
    responseType:'blob'
  })
}

// (新增)布置记录/回收站
export function recordRecyclelist(params){
  return request({
    url: `/fzdTeacherTaskRecord/record/pageList`,
    method: GET,
    params
  })
}


// (新增)撤回布置
export function revocation(id){
  return request({
    url: `/fzdTeacherTaskRecord/revocation/${id}`,
    method: GET,
  })
}

//(新增)布置记录-修改布置
export function updateArrange(data){
  return request({
    url: `/fzdTeacherTaskRecord/update`,
    method: POST,
    data
  })
}

//(新增)布置记录-删除布置
export function taskDel(id){
  return request({
    url: `/fzdTeacherTaskRecord/delete`,
    method: DELETE,
    data:{id}
  })
}


//(新增)布置记录-删除所有回收站中的布置
export function taskDeleteAll(){
  return request({
    url: `/fzdTeacherTaskRecord/delete/revocation/all`,
    method: DELETE,
  })
}