/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-21 14:52:41
 * @LastEditTime: 2021-12-30 17:27:05
 * @LastEditors: linkaiyan
 * @Reference: 
 */


export const QUESTION_TYPE = [
  { label: '全部题型', value: '' },
  { label: '选择题', value: 1 },
  { label: '填空题', value: 3 },
  { label: '判断题', value: 4 },
  { label: '主观题', value: 5 },
]
export const DIFFICULTY = [
  { label: '不限难度', value: '' },
  { label: '容易', value: 1 },
  { label: '较易', value: 2 },
  { label: '一般', value: 3 },
  { label: '较难', value: 4 },
  { label: '困难', value: 5 },
]

export const EDIT_DIFFICULTY = [
  { label: '容易', value: 1 },
  { label: '较易', value: 2 },
  { label: '一般', value: 3 },
  { label: '较难', value: 4 },
  { label: '困难', value: 5 },
]
