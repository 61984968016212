/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2022-03-16 15:07:33
 * @LastEditTime: 2022-04-20 15:04:03
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import * as constants from './constants';
import * as services from '../../../services/check'
import { getTextBookListByDate, getClassListByTextBook } from '../../../services/evalation';

// 修改课本列表
export const changeTextBookList = (data) => ({
  type: constants.CHANGE_TEXTBOOK_LIST,
  data
})

// 修改课本列表
export const changeCurTextBook = (data) => ({
  type: constants.CHANGE_CURRENT_TEXTBOOK,
  data
})

// 修改当前班级列表
export const changeClassList = (data) => ({
  type: constants.CHANGE_CLASS_LIST,
  data
})

// 修改当前班级=
export const changeCurClass = (data) => ({
  type: constants.CHANGE_CURRENT_CLASS,
  data
})

// 修改当前学期
export const changeCurTextbookTerm = (data) => ({
  type: constants.CHANGE_CURRENT_TEXTBOOK_TERM,
  data
})

// 修改开始时间，结束时间
export const changeTimes = (data) => ({
  type: constants.CHANG_TIMES,
  data
})

// 获取班级列表
export const getClassList = (classTermId, textbookId) => {
  // 获取课本列表
  return async dispatch => {
    getClassListByTextBook({
      classTermId: classTermId,
      textbookId: textbookId,
    }).then(res => {
      if(res.data.length) {
        // 有班级
        dispatch(changeClassList(res.data));
        dispatch(changeCurClass(res.data[0].classId))
      } else {
        dispatch(changeClassList([]));
        dispatch(changeCurClass(''));
      }
    }).catch(() => {
      dispatch(changeClassList([]));
      dispatch(changeCurClass(''));
    })
  }
  
}

// 获取测评教材，班级信息
export const getEvaluationMsg = (curbook, curclass, curTerm, startDate='', endDate='', reset=false) => {
  return async dispatch => {
    // 获取教材列表信息
    getTextBookListByDate({
      startTime: startDate,
      endTime: endDate
    }).then(res => {
      if(res?.data?.length > 0) {
        dispatch(changeTextBookList(res.data));
        // 有课本
        if(!reset) {
          if(!res.data.find(item => item.classTermId === curTerm && item.textbookId === curbook)) {
            // 数据没有上次选中课本，重置当前课本，重置当前学期
            dispatch(changeCurTextBook(res.data[0].textbookId));
            dispatch(changeCurTextbookTerm(res.data[0].classTermId))
            // 获取班级信息
            dispatch(getClassList(res.data[0].classTermId, res.data[0].textbookId));
          }
        } else {
          dispatch(changeCurTextBook(res.data[0].textbookId));
          dispatch(changeCurTextbookTerm(res.data[0].classTermId))
          // 获取班级信息
          dispatch(getClassList(res.data[0].classTermId, res.data[0].textbookId));
        }
      } else {
        // 没有课本,当前班级值为空
        dispatch(changeTextBookList([]));
        dispatch(changeClassList([]))
        dispatch(changeCurClass(''));
      }
    }).catch(() => {
      dispatch(changeTextBookList([]));
      dispatch(changeCurTextBook(''));
      dispatch(changeCurTextbookTerm(''));
      dispatch(changeClassList([]));
      dispatch(changeCurClass(''));
    })
  }
  
}
