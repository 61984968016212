import * as services from '../../../services/login'
import * as constants from './constants';
import { message } from 'antd'
import { fromJS } from 'immutable'
import moment from 'moment';
import { recordTeacherUse } from '@/utils/commonFun';

export const changeCountDownNum = (value) => ({
	type: constants.CHANGE_COUNTDOWNNUM,
	value
})


export const changeLogin = (value) => ({
	type: constants.CHANGE_LOGIN,
	value
})

const changeUserInfo = (value) => ( {
	type: constants.CHANGE_USERINFO,
	value: fromJS(value)
})

const changeUpdatePswState = (value) => ( {
	type: constants.CHANGE_UPDATEPSWSTATE,
	value
})


export const setPswState = value => {
	return dispatch => {
		dispatch(changeUpdatePswState(value))
	}
}
export const changeExpiredModalVal = (value) => ( {
	type: constants.CHANGE_ISSHOWEXPIRED,
	value
})
export const changeMsg = (value) => ( {
	type: constants.CHANGE_MSG,
	value
})

/* 获取教师信息 */
export const getTeacherInfo=()=>{
	return async dispatch=>{
		const res = await services.teacherInfo()
		if(res.code === 0 && res.data.isShowFrozenMsg === 1){
			dispatch(changeMsg(res.data.frozenMsg))	
			dispatch(changeExpiredModalVal(true))
		}
	}
}


export const login = (type, phone, codeOrPsw) => {
	return async (dispatch) => {
		try {
			const res = type === 'psw' ? await services.passwordLogin(phone, codeOrPsw) : await services.msgCodeLogin(phone, codeOrPsw)
			message[res.code===0?'success': 'error'](res.msg, 1.5)

			if(res.code === 0) {
				localStorage.isLogin = true
				localStorage.token = res.data.token
				localStorage.userInfo = JSON.stringify(res.data)
				dispatch(changeUserInfo(res.data))
				setTimeout(() => {
					dispatch(changeLogin(true))
					dispatch(getTeacherInfo())
				}, 1000)	
				// 记录教师登录时间
				await services.recordTime(res.data.id);
				localStorage.setItem('l_o_g_t', moment().format('YYYY-MM-DD'));
				// 记录教师使用
				recordTeacherUse();
			} 
		} catch (error) {
			
		}
	}
}


export const sendSms = (phone, type) => {
	return async (dispatch) => {
		try {
			const res =  await services.sendSms(phone, type)
			message[res.code===0?'success': 'error'](res.msg, 1.5)
			if(res.code === 0) {
				dispatch(changeCountDownNum(Date.now() + 1000 * 60))
			} 
		} catch (error) {
			
		}
	}
}


export const updatePassword = (phone, code, psw) => {
	return async (dispatch) => {
		const res = await services.updatePassword(phone, code, psw)
		message[res.code===0?'success': 'error'](res.msg, 1.5)
		if(res.code === 0) {
			dispatch(changeUpdatePswState(true))
		}
	}
}
