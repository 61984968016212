/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-20 10:55:46
 * @LastEditTime: 2021-12-29 09:54:05
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import * as constants from './constants';
import { fromJS } from 'immutable'

const defaultState = fromJS({
  curTextBookData: {},
  curTextBookId: '',
  curSelectId: '',
  curSelectedAssignId: '', // 记录布置时选中的课件/视频/题目id
  curQuestionList: [],
  curQuestionNum: 0,
  // 布置课件总数
  pptTaskNum: 0,
  // 布置视频总数
  videoTaskNum: 0,
  // 布置题目总数
  questionTaskNum: 0,
  // 清空次数
  clearCount: 0,
  //视频数据
  videoData: {},
  showLoading: false,
  //课件数据
  pptData:{},
  //添加教材教师可选年级
  gradeSelectData:[],
  taskId:''
});

export default (state = defaultState, action) => {
  // 修改当前教材数据
  if(action.type === constants.CHANGE_CURRENT_TEXTBOOK_DATA) {
    let temp = state.get('curSelectedAssignId')
    return state.merge({
      curTextBookData: action?.data,
      curTextBookId: action?.data?.id,
      curSelectId: temp ? temp : action.data?.sonCatsList?.[0].id
    })
  }

  // 修改左侧菜单选中id
  if(action.type === constants.CHANGE_SELECT_ID) {
    return state.set('curSelectId', action.data)
  }

  if(action.type === constants.CHANGE_QUESTION_LIST_DATA) {
    return state.merge({
      curQuestionList: action?.data?.rows || [],
      curQuestionNum: action?.data?.count || 0
    })
  }

  // 修改布置资源总数
  if(action.type === constants.CHANGE_TASK_COUNT) {
    console.log(action.data);
    return state.merge({
      pptTaskNum: action.data?.['1']?.count || 0,
      videoTaskNum: action.data?.['2']?.count || 0,
      questionTaskNum: action?.data?.['3']?.count || 0,
    })
  }

  // 修改清空次数
  if(action.type === constants.CHANGE_CLEAR_COUNT) {
    return state.set('clearCount', state.get('clearCount') + 1)
  }

  //视频列表
  if (action.type === constants.CHANGE_VIDEOLIST) {
    return state.set('videoData', action.data)
   }
  if (action.type === constants.CHANGE_SHOWLOADING) {
    return state.set('showLoading', action.value)
  }

  //课件列表
  if (action.type === constants.CHANGE_COURSEWARELIST) {
    return state.set('pptData', action.data)
   }

   //获取添加教材教师可选学科
   if(action.type == constants.GET_GRADE_SELECT){
    return state.set('gradeSelectData', action.data)
   }

  // 修改布置时选中的视频/课件/题目id
  if(action.type === constants.CHANGE_SELECT_ASSIGN_ID) {
    return state.set('curSelectedAssignId', action.data)
  }

  // 修改布置时选中的视频/课件/题目id
  if(action.type === constants.CHANGE_SELECT_ASSIGN_ID) {
    return state.set('curSelectedAssignId', action.data)
  }
   if(action.type == constants.GET_TASKID){
    return state.set('taskId', action.data)
   }

  return state
}

