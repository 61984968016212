/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2022-03-28 15:31:39
 * @LastEditTime: 2022-03-28 15:32:53
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import Loadable from 'react-loadable';
const Component = (loader) => {
  return Loadable({
    loader,
    loading() {
      return <div></div>
    },
  })
};

export default Component;