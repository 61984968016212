import { combineReducers } from 'redux-immutable'
import { reducer as headerReducer } from '../common/header/store';
import { reducer as sideMenuReducer } from '../common/sideMenu/store';
import { reducer as loginReducer } from '../pages/login/store';
import { reducer as videoReducer } from '../pages/video/store';
import { reducer as coursewareReducer } from '../pages/courseware/store';
import { reducer as questionReducer } from '../pages/question/store';
import { reducer as classReducer } from '../pages/class/store';
import { reducer as arrangementReducer } from '../pages/arrangement/store';
import { reducer as issueReducer } from '../pages/issue/store';
import { reducer as checkReducer } from '../pages/check/store';
import { reducer as assignReducer } from '../pages/assign/store';
import { reducer as learntrackReducer } from '../pages/learntrack/store';
import { reducer as evaluationReducer } from '../pages/evaluation/store';
import { reducer as dataOverviewReducer } from '../pages/dataOverview/store';

const reducer = combineReducers({
	header: headerReducer,
	login: loginReducer,
	sideMenu: sideMenuReducer,
	video: videoReducer,
	courseware: coursewareReducer,
	question: questionReducer,
	class: classReducer,
	arrangement: arrangementReducer,
	issue: issueReducer,
	check: checkReducer,
	assign: assignReducer,
	learntrack: learntrackReducer,
	evaluation: evaluationReducer,
	dataOverview: dataOverviewReducer,
});

export default reducer;
