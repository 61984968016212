import * as constants from './constants';
import { fromJS } from 'immutable'
const defaultState = fromJS({
  treeData: [],
  expandKeys: [],
  
})

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  if (action.type === constants.CHANGE_TREEDATA) {
   return state.set('treeData', action.data)
  }
  if (action.type === constants.CHANGE_EXPAND_KEYS) {
   return state.set('expandKeys', action.data)
  }
  

  return state
}