
import * as constants from './constants';
import { fromJS } from 'immutable'

const defaultState = fromJS({
  compareData:null
});

export default (state = defaultState, action) => {
  if(action.type === constants.CHANGE_COMPARE_DATA) {
    return state.set('compareData', action.data)
  }
  if(action.type === constants.CHANGE_FILTER_DATA) {
    return state.set('filterData', action.data)
  }
  if(action.type === constants.CHANGE_PARAM_DATAS) {
    return state.set('paramDatas', action.data)
  }
  if(action.type === constants.CHANGE_QUESTION_DATA) {
    return state.set('questionData', action.data)
  }
  return state
}