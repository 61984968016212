import styled from 'styled-components';

export const QuestionDetailContainer = styled.div`
  width: 100%;
  border: 1px solid #EDEDED;
  border-radius: 6px;
  font-size: 14px;
  color: #3A3A3A;
  line-height: 33px;
  font-weight: 400;
  flex-shrink: 0;
`;

export const TopContainer = styled.div`
  padding: 21px 21px;
  display: flex;
  justify-content: space-between;
  &.jcstart {
    justify-content: flex-start;
    align-items: center;
  }
`;

export const QuestionType = styled.div`

`;

export const QuestionContent = styled.div`
  color: #666666;
  img {
    width: 100%;
  }
`;

export const QuestionOption = styled.div`
  img {
    width: 50%;
    margin: 5px 0;
  }
`;

export const OptionImg = styled.img`
  max-width:400px;
  padding:4px 0;
`
export const ItemImg = styled.img`
  max-width: 500px;
  padding: 8px 0 4px 0;
`
export const ItemOptions = styled.div`
  /* padding-top: 8px; */
`
export const ItemOption = styled.div`
  /* padding: 4px 0; */
  &.right {
    color: #0CCFC9;
  }
  &.wrong {
    color: #ED6461;
  }
`

export const ItemToolBar = styled.div`
.ant-collapse {
  border: none;
  background-color: #fff;
}
.ant-collapse > .ant-collapse-item {
  border: none;
  border-top: 1px dashed #EDEDED;
}
.ant-collapse-item > .ant-collapse-header {
  padding: 0;
}
.ant-collapse-content {
  border: none;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
  cursor: auto;
  padding-left: 0;
}
`
export const ItemToolBarCon = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding: 0 21px;
`
export const ItemToolBarConLeft = styled.div`
  display: flex;
  align-items: center;
  color: #999999;
  font-size: 12px;
`
export const ColorBox = styled.span`
  color: #0CCFC9;
  font-size: 24px;
`;
export const ItemToolBarConRight = styled.div`
  display: flex;
  align-items:center ;
`
export const DropdownBtn = styled.div`
  margin-left:10px ;
  display: flex;
  font-size: 14px;
  align-items: center;
  color: #ED6461;
  cursor: pointer;
`

export const Answer = styled.div`
  padding: 20px 18px;
  box-sizing: border-box;
  font-size: 14px;
  background: #F6F6F6;
  
color: #666666;
  & span{
    color: #666;
  }
`

export const QuestionContainer = styled.div`
width: 637px;
  /* max-width: 493px; */
`

export const TimeContainer = styled.div`
  color: #3A3A3A;
  font-size: 14px;
  font-weight: 400;
`

export const ColorContainer = styled.span`
  color: #0CCFC9;
`
export const RightNum = styled.div`
  width: 57px;
  text-align: center;
  font-size: 30px;
  color: #0CCFC9;
  font-weight: 400;
  margin: 0 120px 0 0;
`;

export const WrongNum = styled.div`
  width: 57px;
  text-align: center;
  font-size: 30px;
  color: #F18381;
  font-weight: 400;
`;

export const EchartsContainer = styled.div`
  display: flex;
  justify-content: center;
  /* width: 100%; */
`;

export const AddToBank = styled.span`
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
`;

export const AlreadyAdd = styled.span`
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #999;
`;

export const AddOrRemeveToTask = styled.span`
  margin-left: 20px;
  font-size: 14px;
  color: #0CCFC9;
  font-weight: 400;
  cursor: pointer;
`;

export const BlackButton = styled.span`
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
`;

export const SysIcon = styled.span`
  padding: 2px 7px;
  border: 1px solid #F8AA00;
  border-radius: 4px;
  line-height: 1;
  font-size: 12px;
  font-weight: 400;
  color: #F8AA00;
  margin-right: 6px;
`;

export const AssignCount = styled.div`
  font-size: 14px;
  color: #888888;
  font-weight: 400;
`;