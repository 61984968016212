
import * as constants from './constants';
import { fromJS } from 'immutable'
const defaultState = fromJS({
  currPage: window.location.pathname,
  isLoading: false
})

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  if (action.type === constants.CHANGE_CURRPAGE) {
   return state.set('currPage', action.value)
  }
  if (action.type === constants.CHANG_LOADING) {
   return state.set('isLoading', action.data)
  }
  return state
}