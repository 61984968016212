import * as constants from './constants';
import { fromJS } from 'immutable'
const defaultState = fromJS({
  tabsShowStatus: true,
  videoTaskList: [],    // 检查视频任务列表
  videoTaskListNum: 0,  // 视频任务数据量
  curVideoTaskId: '',   // 当前视频任务id
  videoList: [],        // 单个任务视频列表
  videoListNum: 0,      // 单个任务视频数据量
  videoReadList: [],    // 单个视频已观看学生列表
  videoReadNum: 0,      // 已观看人数
  videoUnReadList: [],  // 单个视频未观看学生列表
  videoUnReadNum: 0,    // 未观看人数

  pptTaskList: [],    // 检查课件任务列表
  pptTaskListNum: 0,  // 检查课件任务数据量
  pptList: [],        // 单个任务视频列表
  pptListNum: 0,      // 单个任务视频数据量
  pptReadList: [],    // 单个视频已观看学生列表
  pptReadNum: 0,      // 已观看人数
  pptUnReadList: [],  // 单个视频未观看学生列表
  pptUnReadNum: 0,    // 未观看人数

  questionTaskList: [],    // 检查课件任务列表
  questionTaskListNum: 0,  // 检查课件任务数据量
  questionList: [],        // 单个任务题目列表
  questionListNum: 0,      // 单个任务题目数据量

  curQuestionDetail: {},   // 题对人当前题目详情
  currentQuestionId: '',   // 题对人当前题目id
  oneQuestionSize: 0,      // 单条数据查询页数
  questionRightList: [],   // 题对人正确学生列表
  questionRightNum: 0,     // 题对人题目正确人数
  questionWrongList: [],   // 题对人题目错误学生列表
  questionWrongNum: 0,     // 题对人错误人数

  questionScoreList: [],   // 题目成绩列表
  questionScoreNum: 0,     // 题目成绩列表数据量

  curStudentDetail: {},    // 题目人对题当前学生详情
  currentStudentId: '',    // 题目人对题当前学生id
  oneStudentSize: 0,       // 单个学生数据页数
  correctQuestionList: [], // 人对题正确题目列表
  correctQuestionNum: 0,   // 人对题正确题目数据量
  wrongQuestionList: [],   // 人对题错误题目列表
  wrongQuestionNum: 0,     // 人对题错误题目数据量
  taskWrongQuestionList: [], // 任务错题列表 
  taskWrongQuestionNum: 0, // 任务错题数量
})

function handleData(arr) {
  if(!arr.length) {
    return []
  }
  const dd = [{time:arr[0].publishDate.slice(0,10),children: [arr[0]]}]
  for(var i = 1; i<arr.length; i++) {
    const publish = arr[i].publishDate.slice(0,10);
    for(var j = 0; j < dd.length; j++) {
      if(dd[j].time === publish) {
        dd[j].children.push(arr[i])
      }
      // 最后一项不是
      if(j==dd.length-1 && dd[j].time !== publish) {
        dd.push({
          time: publish,
          children: [arr[i]]
        })
        j=j+1;
      }
    }
  }
  return dd
}

export default (state = defaultState, action) => {
  // 切换显示tabs
  if(action.type === constants.CHANGE_TABS_SHOW) {
    return state.set('tabsShowStatus', action.data)
  }

  // 修改视频任务列表
  if (action.type === constants.CHANGE_VIDEO_TASK_LIST_DATA) {
   let data =  handleData(action?.data?.rows || [])
    return state.merge({
      videoTaskList: data,
      videoTaskListNum: action?.data?.count || 0
    })
  }

  // 修改当前视频任务id
  if(action.type == constants.CHANGE_CURRENT_VIDEO_ID) {
    return state.set('curVideoTaskId', action?.data)
  }

  // 修改当前视频任务数据
  if(action.type === constants.CHANGE_VIDEO_LIST_DATA) {
    return state.merge({
      videoList: action?.data?.rows || [],
      videoListNum: action?.data?.count || 0
    })
  }

  // 修改视频任务已观看列表数据
  if(action.type === constants.CHANGE_VIDEO_READ_LIST_DATA) {
    return state.merge({
      videoReadList: action?.data?.rows || [],
      videoReadNum: action?.data?.count || 0
    })
  }

  // 修改视频任务未观看列表数据
  if(action.type === constants.CHANGE_VIDEO_UNREAD_LIST_DATA) {
    return state.merge({
      videoUnReadList: action?.data?.rows || [],
      videoUnReadNum: action?.data?.count || 0
    })
  }

  // 修改课件任务列表
  if (action.type === constants.CHANGE_PPT_TASK_LIST_DATA) {
   let data =  handleData(action?.data?.rows || [])
    return state.merge({
      pptTaskList: data,
      pptTaskListNum: action?.data?.count || 0
    })
  }

  // 修改当前课件任务数据
  if(action.type === constants.CHANGE_PPT_LIST_DATA) {
    return state.merge({
      pptList: action?.data?.rows || [],
      pptListNum: action?.data?.count || 0
    })
  }

  // 修改课件任务已观看列表数据
  if(action.type === constants.CHANGE_PPT_READ_LIST_DATA) {
    return state.merge({
      pptReadList: action?.data?.rows || [],
      pptReadNum: action?.data?.count || 0
    })
  }

  // 修改课件任务未观看列表数据
  if(action.type === constants.CHANGE_PPT_UNREAD_LIST_DATA) {
    return state.merge({
      pptUnReadList: action?.data?.rows || [],
      pptUnReadNum: action?.data?.count || 0
    })
  }






  // 修改问题任务列表
  if (action.type === constants.CHANGE_QUESTION_TASK_LIST_DATA) {
   let data =  handleData(action?.data?.rows || [])
    return state.merge({
      questionTaskList: data,
      questionTaskListNum: action.data.count
    })
  }
  
  // 修改当前题目任务数据
  if(action.type === constants.CHANGE_QUESTION_LIST_DATA) {
    return state.merge({
      questionList: action.data.rows,
      questionListNum: action.data.count
    })
  }

  // 获取当前选中单条问题数据
  if(action.type === constants.CHANGE_CURRENT_QUESTION_DATA) {
    return state.merge({
      curQuestionDetail: action?.data?.rows?.[0] || {},
      oneQuestionSize: action?.data?.count || 0,
      currentQuestionId: action?.data?.rows?.[0].questionId || ''
    })
  }
  // 修改题目正确学生列表
  if(action.type === constants.CHANGE_QUESTION_RIGHT_LIST_DATA) {
    return state.merge({
      questionRightList: action?.data?.rows || [],
      questionRightNum: action?.data?.count || 0,
    })
  }
  // 修改题目错误学生列表
  if(action.type === constants.CHANGE_QUESTION_WRONG_LIST_DATA) {
    return state.merge({
      questionWrongList: action?.data?.rows || [],
      questionWrongNum: action?.data?.count || 0,
    })
  }
  // 修改题目成绩学生列表
  if(action.type === constants.CHANGE_QUESTION_SCORE_LIST_DATA) {
    return state.merge({
      questionScoreList: action?.data?.rows || [],
      questionScoreNum: action?.data?.count || 0,
    })
  }
  // 获取当前选中单个学生数据
  if(action.type === constants.CHANGE_CURRENT_STUDENT_DATA) {
    return state.merge({
      curStudentDetail: action?.data?.rows?.[0] || {},
      oneStudentSize: action?.data?.count || 0,
      currentStudentId: action?.data?.rows?.[0].studentId || '',
    })
  }

  // 修改题目正确题目列表
  if(action.type === constants.CHANGE_CORRECT_QUESTION_LIST_DATA) {
    return state.merge({
      correctQuestionList: action?.data?.rows || [],
      correctQuestionNum: action?.data?.count || 0,
    })
  }
  // 修改题目错误题目列表
  if(action.type === constants.CHANGE_WRONG_QUESTION_LIST_DATA) {
    return state.merge({
      wrongQuestionList: action?.data?.rows || [],
      wrongQuestionNum: action?.data?.count || 0,
    })
  }

  // 修改任务错题列表数据
  if(action.type === constants.CHANGE_TASK_WRONG_QUESTION_LIST_DATA) {
    return state.merge({
      taskWrongQuestionList: action?.data?.rows || [],
      taskWrongQuestionNum: action?.data?.count || 0
    })
  }



  return state
}