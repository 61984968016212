import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'core-js';
import { GlobalStyle} from './style';
import { ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import './statics/iconfont/iconfont'

ReactDOM.render(
  <Fragment>
    <GlobalStyle/>
    <ConfigProvider locale={locale} autoInsertSpaceInButton={false}>
      <App />
    </ConfigProvider>
  </Fragment>,
  document.getElementById('root')
);


