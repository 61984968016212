import { fromJS } from 'immutable';
import * as constants from './constants';

const evil = (fn) => {
	const Fn = Function
	 return new Fn('return ' + fn)()
 }
const defaultState = fromJS({
	countDownNum: 0,
	isLogin: evil(window?.localStorage?.isLogin) || false,
	userInfo: window.localStorage.userInfo? JSON.parse(window.localStorage.userInfo) : {},
	updatePswState: false,
	isShowExpired:false,
	modalMsg:""
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
	switch(action.type) {
		case constants.CHANGE_MSG:
			return state.set('modalMsg', action.value);
		case constants.CHANGE_ISSHOWEXPIRED:
			return state.set('isShowExpired', action.value);
		case constants.CHANGE_COUNTDOWNNUM:
			return state.set('countDownNum', action.value);
		case constants.CHANGE_LOGIN:
			if(!action.value) {
				window.localStorage.token = ''
				window.localStorage.userInfo = ''
				window.localStorage.isLogin = Boolean(false)
				return state.merge({
					countDownNum: 0,
					isLogin: Boolean(false),
					userInfo: {}
				});
			} else {
				return state.set('isLogin', action.value);
			}
		case constants.CHANGE_USERINFO:
			return state.set('userInfo', action.value);
			case constants.CHANGE_UPDATEPSWSTATE:
				return state.set('updatePswState', action.value);
		default:
			return state;
	}
}