/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-20 10:55:57
 * @LastEditTime: 2021-12-29 09:44:04
 * @LastEditors: linkaiyan
 * @Reference: 
 */

export const CHANGE_CURRENT_TEXTBOOK_DATA = 'assign/change_current_textbook_data'

export const CHANGE_SELECT_ID = 'assign/change_select_id'
export const CHANGE_SELECT_ASSIGN_ID = 'assign/change_select_assign_id'

export const CHANGE_QUESTION_LIST_DATA = 'assign/change_question_list_data'
export const CHANGE_TASK_COUNT = 'assign/change_task_count'
export const CHANGE_CLEAR_COUNT = 'assign/change_clear_count'
//视频列表
export const CHANGE_VIDEOLIST = 'video/change_video_list'
export const CHANGE_SHOWLOADING = 'video/change_showloading'
//课件列表
export const CHANGE_COURSEWARELIST = 'courseware/change_courseware_list'
//获取添加教材教师可选学科
export const GET_GRADE_SELECT ="teachingMaterial/grade_data"
//布置任务Id
export const GET_TASKID ="assign/layoutRecordRecycle"
