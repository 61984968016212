
import * as constants from './constants';
import * as services from '../../../services/class'
import {  message, Modal } from 'antd'
import { clearTaskList } from '../../assign/store/actionCreator';
import { setCurBookTaskList } from '../../learntrack/store/actionCreator';

let createHistory = require('history').createHashHistory;
let history = createHistory();  
console.log(history)
const changeTreeData = (data) => ({
  type: constants.CHANGE_TREEDATA,
  data
})

const changeExpandKeys = (data) => ({
  type: constants.CHANGE_EXPAND_KEYS,
  data
})


export const getTreeData = () => {
  return async dispatch => {
    const classData = await services.queryClassList()
    const classList = classData.data
    let arr = []
    let allkeys = []
    if(classList.length) {
      for (const i in classList) {
        arr.push({
          title: classList[i].name,
          key: classList[i].id,
          children:[],
          studentList: []
          })
        const res =  await services.queryAllGroupStudent(classList[i].id)
        const groupList = res.data
          for(const j in groupList) {
            let ids = groupList[j].studentList.map(item => item.studentId)
            if(ids.length>0) {
              allkeys.push(...ids)
              arr[i].children.push({
                title: groupList[j].name,
                key: groupList[j].id,
                children: groupList[j].studentList
              })
              arr[i].studentList.push(...ids);
            }
            
          }
      }
      dispatch(changeExpandKeys(allkeys))
      dispatch(changeTreeData(arr))
    }
  } 
}


export const createTaskRecord = (data, bookId) => {
  return async dispatch => {
    const res =data.id?await services.TaskRecordEdit(data):await services.createTaskRecord(data)
    if(res.code===0) {
      Modal.success({
        title: res.msg,
        content: '',
        onOk() {
          if(!data.id) {
            // 布置完成清空加入布置的内容
            dispatch(clearTaskList(false));
            // 获取学情追踪任务列表
            dispatch(setCurBookTaskList(bookId, false));
          }
          setTimeout(() => {
            history.goBack(1);
          }, 1000);
        }
      })
    } else {
      message.error(res.msg)
    }
  
  } 
}


