
import * as constants from './constants';
import { fromJS } from 'immutable'
const defaultState = fromJS({
  headMenus: [],
  headMenuIds:[],
  menus: [],
  menuIds: []
})

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANGE_HEADERMENU:
      return state.set('headMenus', action.data)
    case constants.CHANGE_HEADMENUIDS:
      return state.set('headMenuIds', action.data)
    case constants.CHANGE_MENUS:
      return state.set('menus', action.data)
    case constants.CHANGE_MENUIDS:
      return state.set('menuIds', action.data)
    default:
      return state
  }
}