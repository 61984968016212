/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2022-01-06 10:39:35
 * @LastEditTime: 2022-01-06 10:53:14
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import { connect } from 'react-redux'
import { LoadingWrapper } from './style';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const MLoading = (props) => {
  const { isLoading } = props;
  return (
    <>
      {
        isLoading ? 
          <LoadingWrapper>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          </LoadingWrapper> : ''
      }
    </>
    
  )

}
const mapState = (state) => ({
  isLoading: state.getIn(['header', 'isLoading']),
})

const mapDispatch = (dispatch) => ({
  
})

export default connect(mapState, mapDispatch)(MLoading);

