import { request } from '../utils/request'

export function passwordLogin (phone, password) {
  return request({
    url: 'fzdTeacher/login',
    method: 'POST',
    data: {
      phone,
      password
    }
  })
}

export function msgCodeLogin (phone, code) {
  return request({
    url: 'fzdTeacher/codeLogin',
    method: 'POST',
    data: {
      phone,
      code
    }
  })
}

export function sendSms (phone, type) {
  return request({
    url: 'sms/sendSms',
    method: 'POST',
    data: {
      phone,
      code: type
    }
  })
}

export function updatePassword (phone, code, password) {
  return request({
    url: 'fzdTeacher/updatePasswordByPhoneCode',
    method: 'POST',
    data: {
      phone,
      phoneCode: code,
      newPassword: password
    }
  })
}
// (新增)教师端-记录教师每日操作时间
export function recordTime (teacherId) {
  return request({
    url: `/fzdTeacherUseLog/record/${teacherId}`,
    method: 'POST',
    noloading: true
  })
}

// (新增)教师端-记录教师每日操作时间，用于页面关闭发送请求
export function recordTime2 (teacherId) {
  return navigator.sendBeacon(process.env.REACT_APP_BASE_URL + `fzdTeacherUseLog/record/${teacherId}`)
}
// (新增)记录登录时间 (每小时一次)
export function recordUse () {
  return request({
    url: '/fzdTeacherUseLog/record/use',
    method: 'GET',
    noloading: true
  })
}
// (新增)设置冻结不再提醒弹窗
export function closeModal () {
  return request({
    url: '/fzdTeacher/frozen/remind/close',
    method: 'PUT',
  })
}


// (新增)获取教师信息
export function teacherInfo () {
  return request({
    url: '/fzdTeacher/info',
    method: 'GET',
  })
}

