/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-21 10:01:25
 * @LastEditTime: 2022-03-21 10:37:23
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import { memo } from 'react';
import { Empty } from 'antd';
import { EmptyWrap, EmptyImg, DevelopImg } from './style';

const EmptyShow = (props) => {
  const { type, desc='暂无内容数据', imageWidth=350, imageHeight=217, fontSize=16, marginTop=26 } = props;

  const ImageIcon = memo(() => {
    return (
      type==='develop' ? <DevelopImg style={{width: imageWidth + 'px', height: imageHeight + 'px'}}></DevelopImg> : <EmptyImg style={{width: imageWidth + 'px', height: imageHeight + 'px'}}></EmptyImg>
    )
  })

  return (
    <EmptyWrap>
      <Empty 
        image={ <ImageIcon/ > }
        imageStyle={{
          height: imageHeight,
        }}
        description={
          <div style={{color: '#999',marginTop: `${marginTop}px`,fontSize: `${fontSize}px`}}>
            {desc}
          </div>
        } 
      />
    </EmptyWrap>
  )

}

export default memo(EmptyShow);
