import { request } from '../utils/request'

const POST = 'POST';
const GET = 'GET';

// 检查-视频列表
export function queryVideoTaskList (params) {
  return request({
    url: 'fzdTeacherTaskRecord/checkVideoTask',
    method: GET,
    params
  })
}

// 检查-视频列表右侧图表
export function queryVideoTaskCharts (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkVideoTaskCharts',
    method: GET,
    params
  })
}

// 检查-视频列表-视频详情
export function queryVideoTaskInfo (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkVideoTask/info',
    method: GET,
    params
  })
}

// 检查-视频列表-视频详情右侧图表
export function queryVideoTaskInfoCharts (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkVideoTaskCharts',
    method: GET,
    params
  })
}

// 检查-视频列表-视频详情-已观看/未观看
export function queryVideoTaskInfoStatus (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkVideoTask/info/submitFlag',
    method: GET,
    params
  })
}









// 检查-课件列表
export function queryPPTTaskList (params) {
  return request({
    url: 'fzdTeacherTaskRecord/checkPptTask',
    method: GET,
    params
  })
}

// 检查-课件列表右侧图表
export function queryPPTTaskCharts (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkPptTaskCharts',
    method: GET,
    params
  })
}

// 检查-课件列表-课件详情
export function queryPPTTaskInfo (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkPptTask/info',
    method: GET,
    params
  })
}

// 检查-课件列表-课件详情右侧图表
export function queryPPTTaskInfoCharts (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkPptTaskCharts',
    method: GET,
    params
  })
}

// 检查-课件列表-课件详情-已观看/未观看
export function queryPPTTaskInfoStatus (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkPptTask/info/submitFlag',
    method: GET,
    params
  })
}















// 检查-题目列表
export function queryQuestionTaskList (params) {
  return request({
    url: 'fzdTeacherTaskRecord/checkQuestionsTask',
    method: GET,
    params
  })
}

// 检查-题目列表右侧图表
export function queryQuestionTaskCharts (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkQuestionsTaskCharts',
    method: GET,
    params
  })
}

// 检查-题目列表-题目详情
export function queryQuestionTaskInfo (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkQuestionsTask/info',
    method: GET,
    params
  })
}

// 检查-题目列表-题目成绩-人对题
export function queryStudentToQuestionInfo (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkQuestionsTask/score/student',
    method: GET,
    params
  })
}

// 检查-题目列表-题目详情-题对人
export function queryQuestionToStudentInfo (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkQuestionsTask/info/student',
    method: GET,
    params
  })
}

// 检查-题目列表-题目成绩
export function queryQuestionScore (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkQuestionsTask/score',
    method: GET,
    params
  })
}

// 检查-题目列表-题目错题
export function queryQuestionWrong (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkQuestionsTask/errors',
    method: GET,
    params
  })
}

// 检查-题目列表-错题/题对人页面中图表接口
export function queryQuestionWrongCharts (params) {
  return request({
    url: '/fzdTeacherTaskRecord/checkQuestionsTask/correctOrErrors',
    method: GET,
    params
  })
}


