import * as constants from './constants';
import { fromJS } from 'immutable'
const defaultState = fromJS({
  videoList: [],
  coursewareList: [],
  questionList: [],
  videoNum: 0,
  coursewareNum: 0,
  questionNum: 0
})

const updateVideoData = (state, action) => {
  return state.merge({
		videoList: action.list,
		videoNum: action.count
	});
}
const updateCoursewareData = (state, action) => {
  return state.merge({
		coursewareList: action.list,
		coursewareNum: action.count
	});
}
const updateQuestionData = (state, action) => {
  return state.merge({
		questionList: action.list,
		questionNum: action.count
	});
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {

  if (action.type === constants.CHANGE_VIDEODATA) {

    return updateVideoData(state, action)
  }
  if (action.type === constants.CHANGE_COURSEWAREDATA) {
    return updateCoursewareData(state, action)
  }
  if (action.type === constants.CHANGE_QUESTIONDATA) {
    return updateQuestionData(state, action)
  }
  
  return state
}