import { connect } from 'react-redux'
import { Link, NavLink, withRouter } from 'react-router-dom'
import * as loginAction  from '../../pages/login/store/actionCreators';
import * as headerAction  from './store/actionCreators';
import { changeTabsShow } from '../../pages/check/store/actionCreator';
import { changeTextBookList, changeClassList, changeCurClass, changeCurTextbookTerm, changeTimes, changeCurTextBook } from '../../pages/evaluation/store/actionCreator';
import { changeCurTextBookData } from '../../pages/assign/store/actionCreator';
import { changeCurBookTaskList, changeCurBookTaskId } from '../../pages/learntrack/store/actionCreator';
import { HeaderWrapper, HanderContainer, Logo, Nav, NavItem, AvatarWrap, LoginBtnStyle, UserInfoWrap, UserInfoCon, UserInfoConInner, UserInfoName, UserInfoAccount, LoginOutBtn,TagWrap } from './style'
import { Avatar, Button, Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useEffect } from 'react';


let loginOutFn = ''

const Hander = (props) => {
  const { isLogin, userInfo, onLoginOut, handleNav } = props
  loginOutFn = onLoginOut
  let key = '';
  // const {aplus_queue} = window;

  useEffect(() => {
    props.history.listen(route => {
      let newKey = route.key;
      if(newKey !== key) {
        // aplus_queue.push({
        //   action: 'aplus.sendPV',
        //   arguments: [{
        //     is_auto: false
        //     }, {
        //       path: route.pathname
        //     }]
        //   });
        window._hmt && window._hmt.push(['_trackPageview', route.pathname]);
      }
      // eslint-disable-next-line
      key = newKey;
    })
  }, [])
  const user = (
    <UserInfoWrap>
      <UserInfoCon>
        <Avatar src={userInfo.avatar} size={40} icon={<UserOutlined />} />
        <UserInfoConInner>
          <UserInfoName>
            {userInfo.get('name')}
          </UserInfoName>
          <UserInfoAccount >
            账号:{userInfo.get('phone')}
          </UserInfoAccount>
        </UserInfoConInner>
      </UserInfoCon>
      <LoginOutBtn onClick={() => {onLoginOut()}}>
        退出登录
      </LoginOutBtn>
    </UserInfoWrap>
  );
  const nav = [
    // {name: 'video',text: '视频', pathname:'/video'}, 
    // {name: 'courseware',text: '课件', pathname:'/courseware' }, 
    // {name: 'question',text: '题目',pathname:'/question'}, 
    {name: 'dataOverview',text: '数据总览',pathname:'/dataOverview'}, 
    {name: 'assign',text: '布置',pathname:'/assign'}, 
    {name: 'track',text: '学情跟踪',pathname:'/learntrack'}, 
    {name: 'class',text: '班级',pathname:'/class'}, 
    // {name: 'arrangement',text: '',pathname:'/arrangement'}, 
    {name: 'check',text: '检查',pathname:'/check'},

    // {name: 'evaluation',text: '测评',pathname:'/evaluation'},
  ]
  if(userInfo.get('type') !== 1) {
    nav.splice(0,1)
  }
  return (
    <HeaderWrapper>
    <HanderContainer>
      {
        userInfo.get('type') === 1 ? <Link to={'/dataOverview'} onClick={()=>{handleNav('/dataOverview')}}><Logo /></Link>:<Link to={'/assign'} onClick={()=>{handleNav('/assign')}}><Logo /></Link>
      }
      <Nav>
        {nav.map(item => {
          return(
          <NavLink onClick={()=>{handleNav(item.pathname)}} key={item.name} to={item.pathname}><NavItem className="navItem">{item.text} </NavItem></NavLink>
          )
        })}
        
      </Nav>
      { isLogin ? 
        <Dropdown overlay={user} placement="bottomRight">
          <AvatarWrap>
            <Avatar size={40}  icon={<UserOutlined />} />
            {
              userInfo.get('type') === 1 ? <TagWrap>领导</TagWrap> :<></>
            }
          </AvatarWrap>
        </Dropdown>
          : 
          <AvatarWrap>
            <Link to="/login">
            <Button type="primary" shape="round" style={LoginBtnStyle}>登录</Button>
          </Link>
        </AvatarWrap>
      }
    </HanderContainer>
    </HeaderWrapper>
  )

}


const mapStateToProps = (state) => {
  return {
    // currPage: state.getIn(['header', 'currPage']),
    isLogin: state.getIn(['login', 'isLogin']),
    userInfo: state.getIn(['login', 'userInfo'])
  }
}

const mapDispathToProps = (dispatch) => {
  return {
    onLoginOut() {
      localStorage.removeItem('l_o_g_t');
      dispatch(headerAction.changeCurrPage('/'))
      dispatch(loginAction.changeLogin(false))
      dispatch(changeTabsShow(true));
      dispatch(changeCurTextBookData({}));
      dispatch(changeCurBookTaskList([]));
      dispatch(changeCurBookTaskId(''))
      dispatch(changeTextBookList([]))
      dispatch(changeClassList([]))
      dispatch(changeCurClass(''))
      dispatch(changeCurTextbookTerm(''))
      dispatch(changeCurTextBook(''))
      dispatch(changeTimes({
        startDate: '',
        endDate: '',
      }))
    },
    handleNav(name) {
      dispatch(headerAction.changeCurrPage(name))
    }
  }
}

export {loginOutFn} 

export default withRouter(connect(mapStateToProps, mapDispathToProps)(Hander))