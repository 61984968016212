/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2022-01-19 14:53:41
 * @LastEditTime: 2022-01-21 13:18:36
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import * as constants from './constants';
import { fromJS } from 'immutable'

const defaultState = fromJS({
  learnTrackBookId: localStorage.getItem('learnTrackBookId') || '',
  learnTrackBook: localStorage.getItem('learnTrackBook') || "{}",
  // 当前课本的任务列表
  curBookTaskList: [],
  // 当前选中的任务
  curBookTaskId: '',
});

export default (state = defaultState, action) => {
  // 修改当前教材任务列表数据
  if(action.type === constants.CHANGE_CUR_BOOK_TASK_LIST) {
    return state.merge({
      curBookTaskList: action?.data,
    })
  }
  // 修改当前教材选中任务数据
  if(action.type === constants.CHANGE_CUR_BOOK_TASK_ID) {
    return state.merge({
      curBookTaskId: action?.data,
    })
  }
  // 修改学情管理当前教材信息
  if(action.type === constants.CHANGE_LEARN_BOOK_DATA) {
    return state.merge({
      learnTrackBook: JSON.stringify(action?.data),
    })
  }
  // 修改学情管理当前教材id
  if(action.type === constants.CHANGE_LEARN_BOOK_ID) {
    return state.merge({
      learnTrackBookId: action?.data,
    })
  }

  return state
}