/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-21 10:01:30
 * @LastEditTime: 2022-01-19 17:39:18
 * @LastEditors: linkaiyan
 * @Reference: 
 */

import styled from 'styled-components';
import nodataPic from '../../statics/no-data.png';
import ePic from '../../statics/Empty.png';

export const EmptyWrap =  styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 250px;
`

export const EmptyImg = styled.div`
  /* width: 350px;
  height: 217px; */
  background: url(${nodataPic});
  background-size: cover;
  background-repeat: no-repeat;
`

export const DevelopImg = styled.div`
  width: 350px;
  height: 217px;
  background: url(${ePic});
  background-size: cover;
  background-repeat: no-repeat;
`