import styled from 'styled-components';

export const SideBarWrap = styled.div`
  z-index: 999999999999;
  position: fixed;
  right: -1px;
  top: 50%;
  transform: translate(0%, -50%);
  width: 77px;
  border: 1px solid #0CCFC9;
  background: #fff;
`

export const SideBarItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px;
  margin: 0 auto;
  padding-top: 4px;
  border-bottom: 1px solid #EAEAEA;
  & .num {
    color: #FFA000;
    font-size: 18px;
  }
  & .title {
    line-height: 1;
    margin: 3px 0 12px;
    font-size: 12px;
    color: #666;
    font-weight: 400px;
  }
  &.last {
    margin-bottom: 11px;
    border-bottom: none;
  }
`

export const SideBarBtn = styled.div`
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #333;
  cursor: pointer;
  transition: all .2s;
  color: #3A3A3A;
  &.primary {
    background: #0CCFC9;
    color: #fff;
    &:hover {
      background-color: #07bbb5;
    }
  }
`

