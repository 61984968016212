/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2021-12-22 15:40:52
 * @LastEditTime: 2022-03-17 11:03:56
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import { Modal } from 'antd';

const MModalTips = (props) => {
  const { title, visible, confirm, cancel, okText='确认', cancelText='取消', width=530, content } = props;
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={confirm}
      onCancel={cancel}
      okText={okText}
      cancelText={cancelText}
      centered
      wrapClassName="m-modal"
      width={width}
    >
      <p style={{textAlign: 'center'}}  dangerouslySetInnerHTML={{__html: content}}></p>
    </Modal>
  )

}

export default MModalTips;