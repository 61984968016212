/*
 * @Description: 
 * @Author: linkaiyan
 * @Date: 2022-04-28 17:14:01
 * @LastEditTime: 2022-05-07 21:29:54
 * @LastEditors: linkaiyan
 * @Reference: 
 */
import { recordUse } from '@/services/login';
import moment from 'moment';

const saveStorage = () => {
  let phone = localStorage.getItem("phone");
  localStorage.setItem(`l-r-t,${phone}`, moment().format('YYYY-MM-DD HH:mm:ss'));
}

const recordTime = () => {
  recordUse().then(res => {
    if(res.code !== 1000) {
      // 保存记录使用时间
      saveStorage();
      let interval = null;
      interval = setInterval(() => {
        recordUse().then(() => {
          saveStorage();
        }).catch(err => {
          if(err === 1000) {
            // 登录过期取消记录
            clearInterval(interval)
          }
        })
      }, 1000*60*60);
    }
  });
}

// 记录教师使用，教师登录后停留在此每隔一个小时记录一次。
export const recordTeacherUse = () => {
  let phone = localStorage.getItem("phone");
  let lastRecordTime = localStorage.getItem(`l-r-t,${phone}`);
  if(lastRecordTime) {
    // 判断上次记录是否已经超过一个小时
    if(phone == localStorage.getItem('phone')) {

    }
    let diff = moment().diff(moment(lastRecordTime), 'hours');
    if(diff >= 1) {
      // 超过一个小时重新记录使用
      recordTime();
    }
  } else {
    recordTime();
  }
}