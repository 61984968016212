import * as constants from './constants';

// 修改对比数据
const changeCompareData = (data) => ({
  type: constants.CHANGE_COMPARE_DATA,
  data
})

export const setCompareData = (data) => {
  return async dispatch => {
    dispatch(changeCompareData(data));
  }
}


// 修改筛选数据
const changeFilterData = (data) => ({
  type: constants.CHANGE_FILTER_DATA,
  data
})

export const setFilterData = (data) => {
  return async dispatch => {
    dispatch(changeFilterData(data));
  }
}

// 修改学生情况筛选数据
const changeParamDatas = (data) => ({
  type: constants.CHANGE_PARAM_DATAS,
  data
})

export const setParamDatas = (data) => {
  return async dispatch => {
    dispatch(changeParamDatas(data));
  }
}


// 保存题目情况数据
const changeQuestionData = (data) => ({
  type: constants.CHANGE_QUESTION_DATA,
  data
})

export const setQuestionData = (data) => {
  return async dispatch => {
    dispatch(changeQuestionData(data));
  }
}
